import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../Sidebar/sidebar";
import { IoClose } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { AiOutlineShoppingCart, AiOutlineSetting } from "react-icons/ai";
import { FaRegUserCircle, FaCheck } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { SlLogin } from "react-icons/sl";
import { BsChevronDown } from "react-icons/bs";
import {initializeApp} from 'firebase/app';
import { collection, query, where, getDocs, getFirestore, doc, setDoc } from "firebase/firestore";

//images
import sidebar_icon from "../../assets/sidebar.png";
import appIcon from "../../assets/appIcon.png";
import persona from "../../assets/persona.svg";
import one from "../../assets/one.png";
import user_img from "../../assets/user_img.png";
import setting from "../../assets/setting.png";
import logout from "../../assets/logout.png";

const firebaseConfig = {
  apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
  authDomain: "verityverification0.firebaseapp.com",
  databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "verityverification0",
  storageBucket: "verityverification0.appspot.com",
  messagingSenderId: "709250129230",
  appId: "1:709250129230:web:321b332165e243328a521f",
  measurementId: "G-QX1DD7CMM7"
};


class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: false,
      isLoggedIn: false,
      kycModal: false,
      domain: "",
      dropdownView: false,
      dropdownView2: false,
      isCartValue: false,
      userData: {},
      email: "",
      photoURL: null,
      fullName: "",
      referenceModal: false,
      enterRefView: true,
      refEmail: "",
      refCode: "",
      greenReferred: false,
      shouldOpenReferenceModal: true
    };
    this.clearAsyncStorage = this.clearAsyncStorage.bind(this);
    this.handleDomainName = this.handleDomainName.bind(this);
    this.handleReferenceEmail = this.handleReferenceEmail.bind(this);
    this.handleReferenceCode = this.handleReferenceCode.bind(this);
  }
  handleDomainName = (event) => {
    this.setState({
      domain: event.target.value,
    });
  }
  async componentDidMount(){
    let referenceCode = localStorage.getItem("referenceCode");
    if(referenceCode)
    {
      this.setState({
        shouldOpenReferenceModal: false
      })
    }

    var userData = JSON.parse(localStorage.getItem('userData'));
    let email = "";
    let data = {}
    if(userData){
      email= userData.email
      this.setState({
          email: userData.email,
          isLoggedIn: true
      })
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data=doc.data()
          this.setState({
              userData: data,
              photoURL: data.photoURL,
              fullName: data.fullName
          })
      });
    }
    var cartName = localStorage.getItem("cartName");
    if(cartName){
      this.setState({
        isCartValue: true
      })
    }
    
  }
  clearAsyncStorage = async() => {
    localStorage.clear();
    window.location.reload();
  }
  handleReferenceCode = (event) => {
    this.setState({
      refCode: event.target.value,
    });
  }
  handleReferenceEmail = (event) => {
    this.setState({
      refEmail: event.target.value,
    });
  }
  proceedReference = () => {
    this.setState({
      greenReferred: true
    })
    let referenceCode = this.state.refCode;
    localStorage.setItem("referenceCode", referenceCode);
    let url = window.location.origin + "/Cart"
    setTimeout(() => {
      this.setState({referenceModal: false})
      window.location.replace(url)
    }, 2000);
  }
  handleSkip = () => {
    this.setState({referenceModal: false})
    let url = window.location.origin + "/Cart";
    window.location.replace(url)
  }
  render() {
    let domain = this.state.domain
    return (
      <>
        <Modal
          show={this.state.kycModal}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="border-radius-2"
        >
          <ModalBody>
            <div>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.setState({ kycModal: false })}
              />
              <p className="uploadYourKYC_txt">Upload your KYC</p>
              <p className="uploadYourKYC_greyTxt">Verify & upload through third party </p>
              <div className="kyc_options_list">
                <Row>
                  <Col md={9} xs={8} sm={8}>
                    <img src={persona} className="persona_icon" />
                  </Col>
                  <Col md={3} xs={4} sm={4}>
                    <div className="navbar_verify_kyc_btn">
                        <p className="navbar_verify_kyc_txt">Verify</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.referenceModal}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="border-radius-10"
        >
          <ModalBody>
              <div>
              {
                  this.state.enterRefView ? 
                  <div className="mopdal_margins">
                    <p className="refernce_header_text">Who referred you?</p>
                    <p className="refernce_header_text_small">Select or type an reference code of that person who referred you.</p>
                    <Row style={{height: 100, marginBottom: 15}}>
                        <Col md={3} sm={3} xs={3}>
                            <div onClick={() => this.setState({enterRefView: false, refEmail: "alex@verityverification.com", refCode: "alexverity10"})}>
                                <img src={user_img} className="cart_image_empty_pointer"/>
                                <p className="ref_usersNames">Alex</p>
                            </div>
                        </Col>
                        <Col md={3} sm={3} xs={3}>
                            <div onClick={() => this.setState({enterRefView: false, refEmail: "john@verityverification.com", refCode: "johnverity10"})}>
                                <img src={user_img} className="cart_image_empty_pointer"/>
                                <p className="ref_usersNames">John</p>
                            </div>
                        </Col>
                        <Col md={3} sm={3} xs={3}>
                            <div onClick={() => this.setState({enterRefView: false, refEmail: "jenny@verityverification.com", refCode: "jennyverity10"})}>
                                <img src={user_img} className="cart_image_empty_pointer"/>
                                <p className="ref_usersNames">Jenny</p>
                            </div>
                        </Col>
                        <Col md={3} sm={3} xs={3}>
                            <div onClick={() => this.setState({enterRefView: false, refEmail: "justin@verityverification.com", refCode: "justinverity10"})}>
                                <img src={user_img} className="cart_image_empty_pointer"/>
                                <p className="ref_usersNames">Justin</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <hr/>
                        </Col>
                        <Col md={1} sm={1} xs={1}>
                            <p className="or_txt">Or</p>
                        </Col>
                        <Col md={5} sm={5} xs={5}>
                            <hr/>
                        </Col>
                    </Row>
                    <p className="enterEmail_cart">Enter Email</p>
                    <Row>
                        <Col md={9} xs={12} sm={12}>
                            <input
                                className="referenceInput"
                                type="text"
                                onChange={(e) => {this.handleReferenceEmail(e)}}
                                value={this.state.refEmail}
                            />
                        </Col>
                        <Col md={3} xs={12} sm={12}>
                            <div className="reference_SEARCH_button_blue" onClick={() => this.setState({enterRefView: false, greenReferred: false})}>
                                <p className="reference_skip_button_txt">Select</p>
                            </div>
                        </Col>
                    </Row>
                    <p className="skip_txt" onClick={this.handleSkip}>Skip</p>
                  </div>
                  :
                  <div className="mopdal_margins">
                    <p className="refernce_header_text">Referral code</p>
                    <p className="refernce_header_text_small">Select or type an reference code of that person who referred you.</p>
                    <div className={this.state.greenReferred ? "index_refView_secndView_green": "index_refView_secndView"}>
                        <Row>
                            <Col md={8} sm={7} xs={7}>
                                <input
                                    className="index_refView_secndInput"
                                    type="text"
                                    onChange={(e) => {this.handleReferenceCode(e)}}
                                    value={this.state.refCode}
                                />
                            </Col>
                            <Col md={4} sm={5} xs={5}>
                                {
                                    this.state.greenReferred ?
                                    <>
                                        <p className="greenCodeReddemmed"><FaCheck size={14} className="FaCheck-icon"/>Redeemed</p>
                                    </>
                                    :
                                        <p className="blackCodeReddemmed" onClick={this.proceedReference}>Redeem</p>
                                }
                            </Col>
                        </Row>
                        <div className="codeRedemmed_index"></div>
                    </div>
                  </div>
              }
              </div>
          </ModalBody>
        </Modal>
        <Nav>
          <div className="side-bar">
            {
              this.state.sideBar ?
              <Row>
                <Col onClick={() => this.setState({ sideBar: false })}>
                  <SideBar sideBarShow={this.state.sideBar}/>
                </Col>
                <Col>
                  <div className="mobile-side-img-div3">
                    <IoClose
                      size={36}
                      className="closeIconX-sidebar"
                      onClick={() => this.setState({ sideBar: false })}
                    />
                </div>
                </Col>
              </Row>
              :
              <Row>
                <Col>
                  <div className="mobile-side-img-div2">
                    <img
                        alt="sidebar"
                        src={sidebar_icon}
                        className="sidebar-icon"
                        onClick={() => this.setState({ sideBar: true })}
                      />
                  </div>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <NavLink to="/">
                    <img
                        alt="sidebar"
                        src={appIcon}
                        className="nav_appIcon"
                      />
                  </NavLink>
                </Col>
                <Col md={5} sm={5} xs={5}>
                  <NavLink to="/cart">
                    <AiOutlineShoppingCart
                        size={25}
                        className="AiOutlineShoppingCart_icon"
                      />
                    </NavLink>
                </Col>
              </Row>
            }
          </div>
          <NavMenu>
            <Row>
              <Col md={1} xs={1} sm={1}>
                <NavLink to="/">
                  <img
                    alt="sidebar"
                    src={appIcon}
                    className="nav_appIcon"
                  />
                </NavLink>
              </Col>
              <Col md={3} xs={7} sm={7} className="navbar_links_alignment Domains_margin">
                <NavLink to="/Domains" onMouseOver={() => this.setState({dropdownView: false, dropdownView2: false})}>
                  <span className="contact-nav-text">DOMAINS</span>
                </NavLink>
                <NavLink to="/comingSoon?page=TOOLS" onMouseOver={() => this.setState({dropdownView: false, dropdownView2: true})} className="Tools_margin">
                  <span className="contact-nav-text">TOOLS<BsChevronDown size={13} className="BsChevronDown-icon2"/></span>
                </NavLink>
                <NavLink to="/comingSoon?page=MARKETPLACE" className="Marketplace_margin" onMouseOver={() => this.setState({dropdownView: false, dropdownView2: false})}>
                  <span className="contact-nav-text">MARKETPLACE</span>
                </NavLink>
              </Col>
              <Col md={4} xs={0} sm={0} className="navbar_searchBox_margin">
                <div className="navbar_searchBox">
                  <Row>
                    <Col md={1} xs={1} sm={1}>
                      <FiSearch
                        size={22}
                        className="navbar_search_icon"
                      />
                    </Col>
                    <Col md={9} xs={5} sm={5}>
                      <input
                          className="navbar_searchInput"
                          type="text"
                          placeholder="Search domains"
                          onChange={(e) => {this.handleDomainName(e)}}
                          value={this.state.domain}
                      />
                    </Col>
                    <Col md={2} xs={6} sm={6}>
                    <NavLink to={"/?search="+domain}>
                      <p className="navbar_searchTxt">SEARCH</p>
                      </NavLink>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3} xs={4} sm={4} className="other_options_Right">
                <div>
                  <div style={{marginLeft:"7%", marginRight: "15%" }} className="cart_left_float_new">
                    <NavLink to="/comingSoon?page=WALLET">
                      <span className="contact-nav-text">WALLET</span>
                    </NavLink>
                  </div>
                </div>
                {
                  this.state.shouldOpenReferenceModal ?
                    <div onClick={() => this.setState({referenceModal: true})} >
                      <div className="cart_left_float_new">
                        <AiOutlineShoppingCart
                          size={25}
                          className="AiOutlineShoppingCart_PC"
                        />
                      </div>
                    </div>
                  :
                  <>
                    {
                      this.state.isCartValue ?
                        <NavLink to="/Cart" onMouseOver={() => this.setState({dropdownView: false})}>
                          <span className="contact-nav-text">
                            <AiOutlineShoppingCart
                              size={25}
                              className="AiOutlineShoppingCart_PC"
                            />
                            <img src={one} className="one_notif"/>
                          </span>
                        </NavLink>
                      :
                      <NavLink to="/Cart"  onMouseOver={() => this.setState({dropdownView: false})}>
                        <span className="contact-nav-text">
                          <AiOutlineShoppingCart
                            size={25}
                            className="AiOutlineShoppingCart_PC"
                          />
                        </span>
                      </NavLink>
                    }
                  </>
                }
                {
                  this.state.isLoggedIn ?
                    <NavLink to="/Settings" onMouseOver={() => this.setState({dropdownView: true})}>
                      <span className="contact-nav-text" style={{marginLeft:"15%"}}><AiOutlineSetting size={25} className="BiUserCircle-icon"/></span>
                    </NavLink>
                  :
                    <NavLink to="/Login">
                      <span className="contact-nav-text" style={{marginLeft:"15%"}}><SlLogin size={24} className="BiUserCircle-icon"/></span>
                    </NavLink>
                }
                {
                    this.state.dropdownView ?
                        <div className="dropdownView" onMouseOver={() => this.setState({dropdownView: true})} onMouseOut={() => this.setState({dropdownView: false})}>
                          <Row>
                            <Col md={2}>
                            {
                                this.state.photoURL != "" ?
                                    <img src={this.state.photoURL} className="dropdownView_image_empty"/>
                                :
                                    <img src={user_img} className="dropdownView_image_empty"/>
                            } 
                            </Col>
                            <Col md={10}>
                                {
                                    this.state.fullName === undefined ?
                                        <p className="dropdownView_fullName">Name not set</p>
                                    :
                                        <p className="dropdownView_fullName">{this.state.fullName}</p>
                                }
                                <p className="dropdownView_Email">{this.state.email}</p>
                            </Col>
                          </Row>
                          <div className="dropdown_optionsTab">
                            <NavLink to="/Settings">
                              <p className="profile_humanity_settingsTxt" onClick={this.navigateSettings}><img src={setting} className="profile_humanity_settings"/>Account Settings</p>
                            </NavLink>
                            
                            <p className="profile_humanity_settingsTxtred" onClick={this.clearAsyncStorage}><img src={logout} className="profile_humanity_settingsSignout"/>Sign Out</p>
                          </div>
                          
                        </div>
                      :
                      <></>
                }
                {
                    this.state.dropdownView2 ?
                        <div className="dropdownView2" onMouseOver={() => this.setState({dropdownView2: true})} onMouseOut={() => this.setState({dropdownView2: false})}>
                          <NavLink to="/comingSoon?page=SUBDOMAINS">
                            <p className="profile_humanity_settingsTxt_2" onClick={this.navigateSettings}>SUBDOMAINS</p>
                          </NavLink>
                          <NavLink to="/comingSoon?page=BULK MANAGE">
                            <p className="profile_humanity_settingsTxt_2" onClick={this.navigateSettings}>BULK MANAGE</p>
                          </NavLink>
                          <NavLink to="/comingSoon?page=AGGREGATE SEARCH">
                            <p className="profile_humanity_settingsTxt_2" onClick={this.navigateSettings}>AGGREGATE SEARCH</p>
                          </NavLink>
                          <NavLink to="/comingSoon?page=VALUATION">
                            <p className="profile_humanity_settingsTxt_2" onClick={this.navigateSettings}>VALUATION</p>
                          </NavLink>
                        </div>
                      :
                      <></>
                }
              </Col>
              <Col md={1} xs={0} sm={0} className="claimFREE_btn_margin">
                <NavLink to="/FreeDomains">
                  <div className="claimFREE_btn">
                    <span className="contact-nav-text_white">CLAIM FREE DOMAIN</span>
                  </div>
                </NavLink>
                
              </Col>
            </Row>         
          </NavMenu>
        </Nav>
      </>
    );
  }
}

export default Navbar;
