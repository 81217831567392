import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {initializeApp} from 'firebase/app';
import { collection, query, where, getDocs, getFirestore, doc, setDoc } from "firebase/firestore";


import check from "../assets/check.png";
import wallet from "../assets/wallet.png";
import user_img from "../assets/user_img.png";
import Footer from "../Components/Footer/footer";
import "./Cart.css";

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load({
  apiKey: "jdR4AYlQ1Ir7NtzikMO3",
  region: "ap"
})

const firebaseConfig = {
    apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
    authDomain: "verityverification0.firebaseapp.com",
    databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "verityverification0",
    storageBucket: "verityverification0.appspot.com",
    messagingSenderId: "709250129230",
    appId: "1:709250129230:web:321b332165e243328a521f",
    measurementId: "G-QX1DD7CMM7"
};
const { chains, provider } = configureChains(
    [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    [alchemyProvider({ apiKey: 'obD9VwIIHamZFQ5I09pDi810_UWYN-5d' }), publicProvider()],
);

const { connectors } = getDefaultWallets({
    appName: "Verity Verification",
    chains
});

const wagmiClient = createClient({
    autoConnect: false,
    connectors,
    provider
});
class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartName:"",
            cartPrice: 0,
            email: "",
            userData: {},
            photoURL: "",
            fullName: "",
            slicedWallet: "",
            referenceModal: false,
            enterRefView: true,
            referenceCode:"",
            referenceCodeSection: false,
            refEmail: "",
            referenceCodeFinal: "",
            isLoggedIn: false
        }
        this.handleReferenceCode = this.handleReferenceCode.bind(this);
        this.handleReferenceEmail = this.handleReferenceEmail.bind(this);
    }
  async componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    fpPromise
    .then(fp => fp.get())
    .then(result => console.log("FingerprintJS Visitor ID ====>", result.visitorId))

    var userData = JSON.parse(localStorage.getItem('userData'));
    let email = "";
    let data = {}
    if(userData){
        email= userData.email
        this.setState({
            email: userData.email
        })
    }
    
    var cartName = localStorage.getItem("cartName");
    var cartPrice= localStorage.getItem("cartPrice");
    this.setState({
        cartName: cartName,
        cartPrice: cartPrice
    })

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data=doc.data()
        this.setState({
            userData: data,
            photoURL: data.photoURL,
            fullName: data.fullName,
            isLoggedIn: true
        })
    });
    setTimeout(() => {
        let walletAddress = this.state.userData.walletAddress;
        let startingWA = walletAddress.slice(0, 5);
        let endingWA = walletAddress.substr(-5);
        console.log(startingWA+"..."+endingWA)
        this.setState({
            slicedWallet: startingWA+"..."+endingWA,
        })
      }, 2000);
      var referenceCode = localStorage.getItem('referenceCode');
      if(referenceCode){
          this.setState({
              referenceCode: referenceCode,
              referenceCodeSection: true,
              referenceCodeFinal: referenceCode
          })
      }
      let checkoutData = JSON.parse(localStorage.getItem('checkOutResponse'))
      console.log(checkoutData)
  }
  handleReferenceCode = (event) => {
    this.setState({
        referenceCode: event.target.value,
    });
  }
  handleWalletLogin = async (account) => {
    let walletAddress = account.address;
    let balanceSymbol = account.balanceSymbol;
    let docID = ""
    const app = initializeApp(firebaseConfig);
    let email = this.state.email;
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { walletAddress: walletAddress, isWalletConnected: true, walletChain: balanceSymbol }, { merge: true });
    this.componentDidMount()
  }
  deleteCart = () => {
    window.localStorage.removeItem("cartName");
    window.localStorage.removeItem("cartPrice");
    this.componentDidMount()
  }
  handleSkip = () => {
    this.setState({referenceModal: false})
  }
  handleReferenceEmail = (event) => {
    this.setState({
      refEmail: event.target.value,
    });
  }
  proceedReference = () => {
    this.setState({
      greenReferred: true,
      referenceCodeSection: true,
    })
    let referenceCode = this.state.referenceCode;
    localStorage.setItem("referenceCode", referenceCode);
    setTimeout(() => {
      this.setState({referenceModal: false, referenceCodeFinal: this.state.referenceCode})
    }, 2000);
  }
  navigateRegister = () => {
    this.props.history.push("/Register?warn=Register");
  }
  placeOrder = async() => {
    let domain = this.state.cartName;
    let buyerAddress = this.state.userData.walletAddress;
    let buyerEmail = this.state.email;
    let data = {
        "payment": {
          "method": "stripe"
        },
        "domains": [
          {
            "name": "yfdjdsf.nft",
            "ownerAddress": buyerAddress,
            "email": buyerEmail
          }
        ]
    }
    let body = JSON.stringify(data)
    console.log(body)
    await fetch("https://api.ud-sandbox.com/api/v2/resellers/identityunchained/orders", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + "2pwoo7a5kkbfjs4ril93wqpp0zignw9y",
          },
          body: body,
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson);
            if(responseJson.total){
                localStorage.setItem("checkOutResponse", JSON.stringify(responseJson));
                this.props.history.push("/Stripe");
            }
            else{
                alert("DOMAIN NOT AVAILABLE")
            }
        })
        .catch((error) => {
            console.log(error)
            alert(error)
        })
  }
  render() {
    return (
        <>
            <Modal
                show={this.state.referenceModal}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="border-radius-10"
            >
                <ModalBody>
                    <div>
                    {
                        this.state.enterRefView ? 
                        <div className="mopdal_margins">
                            <p className="refernce_header_text">Who referred you?</p>
                            <p className="refernce_header_text_small">Select or type an reference code of that person who referred you.</p>
                            <Row style={{height: 100}}>
                                <Col md={3} sm={3} xs={3}>
                                    <div onClick={() => this.setState({enterRefView: false, refEmail: "alex@verityverification.com", refCode: "alexverity10", greenReferred: false})}>
                                        <img src={user_img} className="cart_image_empty_pointer"/>
                                        <p className="ref_usersNames">Alex</p>
                                    </div>
                                </Col>
                                <Col md={3} sm={3} xs={3}>
                                    <div onClick={() => this.setState({enterRefView: false, refEmail: "john@verityverification.com", refCode: "johnverity10", greenReferred: false})}>
                                        <img src={user_img} className="cart_image_empty_pointer"/>
                                        <p className="ref_usersNames">John</p>
                                    </div>
                                </Col>
                                <Col md={3} sm={3} xs={3}>
                                    <div onClick={() => this.setState({enterRefView: false, refEmail: "jenny@verityverification.com", refCode: "jennyverity10", greenReferred: false})}>
                                        <img src={user_img} className="cart_image_empty_pointer"/>
                                        <p className="ref_usersNames">Jenny</p>
                                    </div>
                                </Col>
                                <Col md={3} sm={3} xs={3}>
                                    <div onClick={() => this.setState({enterRefView: false, refEmail: "justin@verityverification.com", refCode: "justinverity10", greenReferred: false})}>
                                        <img src={user_img} className="cart_image_empty_pointer"/>
                                        <p className="ref_usersNames">Justin</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6} xs={6}>
                                    <hr/>
                                </Col>
                                <Col md={1} sm={1} xs={1}>
                                    <p className="or_txt">Or</p>
                                </Col>
                                <Col md={5} sm={5} xs={5}>
                                    <hr/>
                                </Col>
                            </Row>
                            <p className="enterEmail_cart">Enter Email</p>
                            <Row>
                                <Col md={9} xs={12} sm={12}>
                                    <input
                                        className="referenceInput"
                                        type="text"
                                        onChange={(e) => {this.handleReferenceEmail(e)}}
                                        value={this.state.refEmail}
                                    />
                                </Col>
                                <Col md={3} xs={12} sm={12}>
                                    <div className="reference_SEARCH_button_blue" onClick={() => this.setState({enterRefView: false, greenReferred: false})}>
                                        <p className="reference_skip_button_txt">Select</p>
                                    </div>
                                </Col>
                            </Row>
                            <p className="skip_txt" onClick={this.handleSkip}>Skip</p>
                        </div>
                        :
                        <div className="mopdal_margins">
                            <p className="refernce_header_text">Referral code</p>
                            <p className="refernce_header_text_small">Select or type an reference code of that person who referred you.</p>
                            <div className={this.state.greenReferred ? "index_refView_secndView_green": "index_refView_secndView"}>
                                <Row>
                                    <Col md={8} sm={7} xs={7}>
                                        <input
                                            className="index_refView_secndInput"
                                            type="text"
                                            onChange={(e) => {this.handleReferenceCode(e)}}
                                            value={this.state.referenceCode}
                                        />
                                    </Col>
                                    <Col md={4} sm={5} xs={5}>
                                        {
                                            this.state.greenReferred ?
                                            <>
                                                <p className="greenCodeReddemmed"><FaCheck size={14} className="FaCheck-icon"/>Redeemed</p>
                                            </>
                                            :
                                                <p className="blackCodeReddemmed" onClick={this.proceedReference}>Redeem</p>
                                        }
                                    </Col>
                                </Row>
                                <div className="codeRedemmed_index"></div>
                            </div>
                        </div>
                    }
                    </div>
                </ModalBody>
            </Modal>
            <div className="settings_body">
                <p className="settings_header_title">Your Cart</p>
                <Row>
                    <Col md={8} sm={12} xs={12}>
                        <div className="cart_left_firstDiv">
                            {
                                this.state.cartName ?
                                <Row>
                                    <Col md={7} sm={6} xs={6}>
                                        <p className="card_domainName">{this.state.cartName}</p>
                                    </Col>
                                    <Col md={3} sm={3} xs={3}>
                                        <p className="card_domainPrice">${this.state.cartPrice}.00</p>
                                    </Col>
                                    <Col md={2} sm={3} xs={3}>
                                        <p className="red_delete" onClick={this.deleteCart}>Delete</p>
                                    </Col>
                                </Row>
                                :
                                <center>
                                    <p className="card_domainName">Cart Empty</p>
                                </center>
                            }
                            
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="cart_right_firstDiv">
                            <p className="settings_header_right_title">ORDER SUMMERY</p>
                            <div className="cart_hr"></div>
                            <Row>
                                <Col md={2} sm={3} xs={3}>
                                {
                                    this.state.photoURL != "" ?
                                        <img src={this.state.photoURL} className="cart_image_empty"/>
                                    :
                                        <img src={user_img} className="cart_image_empty"/>
                                } 
                                </Col>
                                <Col md={10} sm={9} xs={9}>
                                    {
                                        this.state.fullName === undefined ?
                                            <p className="cart_fullName">Name not set</p>
                                        :
                                            <p className="cart_fullName">{this.state.fullName}</p>
                                    }
                                    {
                                        this.state.userData.walletAddress === "" ?
                                            <>
                                                <WagmiConfig client={wagmiClient}>
                                                    <RainbowKitProvider chains={chains}>
                                                        <ConnectButton.Custom>
                                                            {({
                                                                account,
                                                                chain,
                                                                openAccountModal,
                                                                openChainModal,
                                                                openConnectModal,
                                                                authenticationStatus,
                                                                mounted,
                                                            }) => {
                                                                const ready = mounted && authenticationStatus !== 'loading';
                                                                const connected =
                                                                ready &&
                                                                account &&
                                                                chain &&
                                                                (!authenticationStatus ||
                                                                authenticationStatus === 'authenticated');
                                                                return (
                                                                <div
                                                                    {...(!ready && {
                                                                    'aria-hidden': true,
                                                                    'style': {
                                                                        opacity: 0,
                                                                        pointerEvents: 'none',
                                                                        userSelect: 'none',
                                                                    },
                                                                    })}
                                                                >
                                                                    {(() => {
                                                                    if (!connected) {
                                                                        return (
                                                                        <div className="cart_social_button" onClick={openConnectModal}>
                                                                            <Row>
                                                                                <Col md={2} xs={2} sm={2}>
                                                                                    <img src={wallet} className="cart_social_button_icon"/>
                                                                                </Col>
                                                                                <Col md={10} xs={10} sm={10}>
                                                                                    <span className="cart_social_button_text">Connect Wallet</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        );
                                                                    }

                                                                    if (chain.unsupported) {
                                                                        return (
                                                                        <button onClick={openChainModal} type="button">
                                                                            Wrong network
                                                                        </button>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <div className="cart_social_button_connected" onClick={() => this.handleWalletLogin(account)}>
                                                                            <Row>
                                                                                <Col md={9} xs={9} sm={9}>
                                                                                    <span className="cart_social_button_text_white">Fetch Address</span>
                                                                                </Col>
                                                                                <Col md={1} xs={1} sm={1}>
                                                                                    <img src={check} className="cart_social_button_icon"/>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    );
                                                                    })()}
                                                                </div>
                                                                );
                                                            }}
                                                        </ConnectButton.Custom>
                                                    </RainbowKitProvider>
                                                </WagmiConfig>
                                            </>
                                        :
                                            <p className="cart_walletAddress">{this.state.slicedWallet}<FaRegCopy size={14} className="FaRegCopy-icon" onClick={() => {navigator.clipboard.writeText(this.state.userData.walletAddress)}}/></p>
                                    }
                                </Col>
                            </Row>
                            <div className={this.state.referenceCodeSection ? "index_refView_secndView_green_withoutShadow": "index_refView_secndView_withoutShadow"} onClick={() => this.setState({referenceModal: true, enterRefView: true})}>
                                <Row>
                                    <Col md={6} sm={6} xs={6}>
                                        <input
                                            className="index_refView_secndInput"
                                            type="text"
                                            placeholder="Enter code"
                                            disabled
                                            onChange={(e) => {this.handleReferenceCode(e)}}
                                            value={this.state.referenceCodeFinal}
                                        />
                                    </Col>
                                    <Col md={6} sm={6} xs={6}>
                                        {
                                            this.state.greenReferred ?
                                            <>
                                                <p className="greenCodeReddemmed"><FaCheck size={14} className="FaCheck-icon"/>Redeemed</p>
                                            </>
                                            :
                                                <p className="blackCodeReddemmed" onClick={this.proceedReference}>Redeem</p>
                                        }
                                    </Col>
                                </Row>
                                <div className="codeRedemmed_index"></div>
                            </div>
                            <hr></hr>
                            <Row>
                                <Col md={6} sm={6} xs={6}>
                                    <p className="totalDue">Total Due: </p>
                                </Col>
                                <Col md={6} sm={6} xs={6}>
                                    <p className="totalAmmount">${this.state.cartPrice}.00</p>
                                </Col>
                            </Row>
                            {
                                this.state.cartName ?
                                <>
                                    {
                                        this.state.isLoggedIn ?
                                        <button class="checkOut" onClick={this.placeOrder}>
                                            Checkout
                                        </button>
                                        :
                                        <button class="checkOut" onClick={this.navigateRegister}>
                                                Checkout
                                        </button>
                                    }
                                </>
                                    
                                :
                                    <button class="checkOut_inactive">
                                            Checkout
                                    </button>
                            }
                            
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer/>
        </>
    );
  }
}

export default withRouter(Cart);
