import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";
import Loader from "react-js-loader";

import "./Dashboard.css";
import M2 from "../assets/2M+.png";
import Hundred from "../assets/100+.png";
import TwoHundred from "../assets/204+.png";
import Fourty from "../assets/40+.png";
import unstoppable from "../assets/unstoppable.png";
import persona from "../assets/persona.png";

const queryParams =  new URLSearchParams(window.location.search);

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domain:"",
            errors:{},
            loaderView: false,
            domainData: [],
            referenceModal: false,
            refenceCode: "",
            enterRefView: false,
            searchView: false,
            halfValue : 0,
            preferenceDomain : "All"
        }
        this.handleDomainName = this.handleDomainName.bind(this);
    }
  async componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    let type = queryParams.get("search")
    if(type){
      this.setState({
        domain: type,
        searchView: true
      })
      setTimeout(() => {
        this.getDomainResults()
      }, 1000);
    }
  }
  handleDomainName = (event) => {
    let errors = this.state.errors;
    let domain = event.target.value.length
    if (domain>0) {
      errors["domain"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["domain"] = "Cannot be empty";
      this.setState({ errors: errors});
    }
    this.setState({
      domain: event.target.value,
    });
  }
  navigateProfile(){
    this.props.history.push("/Profile");
  }
  handleSearch = () => {
    let errors = this.state.errors;
    let domain = this.state.domain
    if (domain != "") {
      errors["domain"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["domain"] = "Cannot be empty";
      this.setState({ errors: errors});
    }
    let domainWarning = this.state.errors["domain"];
    if(domain != "" && domainWarning == null){
      this.proceedSearch()
    }
  }
  proceedSearch = async () => {
    this.getDomainResults();
    this.setState({
      referenceModal: false,
      searchView: true,
      enterRefView: false
    })
  }
  getDomainResults = async () => {
    this.setState({
      loaderView: true
    })
    let domain = this.state.domain;
    await fetch(`https://unstoppabledomains.com/api/v2/resellers/identityunchained/domains/suggestions?search=`+domain, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          domainData: responseJson,
          halfValue: Object.keys(responseJson).length/2
        })
        console.log("OUTPUT ==> ", Object.keys(responseJson).length/2);
      })
      .catch((error) => {
        console.error(error);
      });
      this.setState({
        loaderView: false
      })
  };
  renderDomainData = () => {
    let domainData = this.state.domainData;
    let halfValue = this.state.halfValue;
    let slicedDomainsLeft = domainData.slice(0, halfValue)
    let slicedDomainsRight = domainData.slice(halfValue+1, )
    return(
      <div>
        <Row>
          <Col md={6} sm={12} xs={12}>
            {
              slicedDomainsLeft.map((item, key) =>(
                <>
                  {
                    key%2 === 0 ?
                      <div className="domains_boxes_odd">
                        <Row>
                          <Col md={8} sm={7} xs={7}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1} sm={2} xs={2}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                            <button className="buyBtn" onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    :
                    <div className="domains_boxes_even">
                        <Row>
                          <Col md={8} sm={7} xs={7}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1} sm={2} xs={2}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                            <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                    </div>
                  }
                </>
              ))
            }
          </Col>
          <Col md={6} sm={12} xs={12}>
            {
              slicedDomainsRight.map((item, key) =>(
                <>
                  {
                    key%2 === 0 ?
                      <div className="domains_boxes_odd_forRight">
                        <Row>
                          <Col md={8} sm={7} xs={7}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1} sm={2} xs={2}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                            <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    :
                    <div className="domains_boxes_even_forRight">
                        <Row>
                          <Col md={8} sm={7} xs={7}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1} sm={2} xs={2}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                            <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                    </div>
                  }
                </>
              ))
            }
          </Col>
        </Row>
      </div>
    )
  }
  handleFilter = (domain) => {
    this.setState({
      preferenceDomain: domain
    })
  }
  renderFilteredDomainData = () => {
    let preferenceDomain = this.state.preferenceDomain;
    let domainData = this.state.domainData;
    return(
      <div>
        <Row>
          <Col md={6} sm={12} xs={12}>
            {
              domainData.map((item, key) =>(
                <>
                  {
                    item.name.includes(preferenceDomain) ? 
                    <>
                      {
                        key%2 === 0 ?
                          <div className="domains_boxes_odd">
                            <Row>
                              <Col md={8} sm={7} xs={7}>
                                <span className="domains_boxes_odd_txt">{item.name}</span>
                              </Col>
                              <Col md={1} sm={2} xs={2}>
                                <span className="domains_boxes_odd_price">${item.price}</span>
                              </Col>
                              <Col md={3} sm={3} xs={3}>
                                <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                                  <span>Buy</span>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        :
                        <div className="domains_boxes_even">
                            <Row>
                              <Col md={8} sm={7} xs={7}>
                                <span className="domains_boxes_odd_txt">{item.name}</span>
                              </Col>
                              <Col md={1} sm={2} xs={2}>
                                <span className="domains_boxes_odd_price">${item.price}</span>
                              </Col>
                              <Col md={3} sm={3} xs={3}>
                                <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                                  <span>Buy</span>
                                </button>
                              </Col>
                            </Row>
                        </div>
                      }
                    </>
                    :
                    <></>
                  }
                </>
              ))
            }
          </Col>
        </Row>
      </div>
    )
  }
  addToCart = (item) => {
    localStorage.setItem("cartName", item.name);
    localStorage.setItem("cartPrice", item.price);
    this.props.history.push("/Cart")
  }
  render() {
    return (
        <div className="dashboard-container-box">
            {
              this.state.searchView ?
              <div className="dashboard_searchMain">
                <div className="dashboard_body_LR_background_gray"></div>
                <Row className="dashbord_div1_margins">
                  <Col md={10} sx={8} xs={9}>
                      <input
                          className="dashboard_DomainInput"
                          type="text"
                          value={this.state.domain}
                          placeholder="Search for your name.crypto"
                          onChange={(e) => {this.handleDomainName(e)}}
                      />
                      {  
                          this.state.errors["domain"] ? (
                              <span
                                  id="marginInputs_Dashboard"
                                  className="validateErrorTxt"
                              >
                                  {this.state.errors["domain"]}
                              </span>
                          ) :
                          (
                              <div className="registerInputMargin"></div>
                          )
                      }
                  </Col>
                  <Col md={2} sm={3} xs={3}>
                    <button className="searchBtn" onClick={this.handleSearch}>
                      <FiSearch size={23} className="FiSearch-icon"/><span className="searchBtn_txt">Search</span>
                    </button>
                  </Col>                   
                </Row>
                <p className="searchResults_txt">Result for : "{this.state.domain}"</p>
                <div className="dashboard_domains_tab">
                  {
                    this.state.preferenceDomain === "All" ?
                      <button className="dashboard_domains_tab_active_first" onMouseOver={() => this.setState({optionsDropDown: false})}>
                        <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">All domains</span>
                      </button>
                    :
                      <button className="dashboard_domains_tab_inactive_first" onClick={() => this.handleFilter("All")} onMouseOver={() => this.setState({optionsDropDown: false})}>
                        <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">All domains</span>
                      </button>
                  }
                  {
                    this.state.preferenceDomain === ".nft" ?
                    <button className="dashboard_domains_tab_active" onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.nft</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".nft")} onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.nft</span>
                    </button>
                  }
                  {
                    this.state.preferenceDomain === ".crypto" ?
                    <button className="dashboard_domains_tab_active" onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.crypto</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".crypto")} onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.crypto</span>
                    </button>
                  }
                  {
                    this.state.preferenceDomain === ".blockchain" ?
                    <button className="dashboard_domains_tab_active" onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.blockchain</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".blockchain")} onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.blockchain</span>
                    </button>
                  }
                  {
                    this.state.preferenceDomain === ".wallet" ?
                    <button className="dashboard_domains_tab_active" onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.wallet</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".wallet")} onMouseOver={() => this.setState({optionsDropDown: false})}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.wallet</span>
                    </button>
                  }
                  <button className="dashboard_domains_tab_inactive otherBtn" onClick={() => this.handleFilter(".wallet")} onMouseOver={() => this.setState({optionsDropDown: true})}>
                      <span className="searchBtn_txt">Others <BsChevronDown size={13} className="BsChevronDown-icon"/></span>
                  </button>
                  {
                    this.state.optionsDropDown ?
                        <div className="more_View" onMouseOver={() => this.setState({optionsDropDown: true})} onMouseOut={() => this.setState({optionsDropDown: false})}>
                        {
                            this.state.preferenceDomain === ".x" ?
                            <button className="othersActive">
                                <span className="othersTxt">.x</span>
                            </button>
                            :
                            <button className="othersGrey" onClick={() => this.handleFilter(".x")}>
                                <span className="othersTxt">.x</span>
                            </button>
                        }
                        <hr className="dropDownOptions"/>
                        {
                            this.state.preferenceDomain === ".bitcoin" ?
                            <button className="othersActive">
                                <span className="othersTxt">.bitcoin</span>
                            </button>
                            :
                            <button className="othersWhite" onClick={() => this.handleFilter(".bitcoin")}>
                                <span className="othersTxt">.bitcoin</span>
                            </button>
                        }
                        <hr className="dropDownOptions"/>
                        {
                            this.state.preferenceDomain === ".dao" ?
                            <button className="othersActive">
                                <span className="othersTxt">.dao</span>
                            </button>
                            :
                            <button className="othersGrey" onClick={() => this.handleFilter(".dao")}>
                                <span className="othersTxt">.dao</span>
                            </button>
                        }
                        <hr className="dropDownOptions"/>
                        {
                            this.state.preferenceDomain === ".888" ?
                            <button className="othersActive">
                                <span className="othersTxt">.888</span>
                            </button>
                            :
                            <button className="othersWhite" onClick={() => this.handleFilter(".888")}>
                                <span className="othersTxt">.888</span>
                            </button>
                        }
                        <hr className="dropDownOptions"/>
                        {
                            this.state.preferenceDomain === ".zil" ?
                            <button className="othersActive">
                                <span className="othersTxt">.zil</span>
                            </button>
                            :
                            <button className="othersGrey" onClick={() => this.handleFilter(".zil")}>
                                <span className="othersTxt">.zil</span>
                            </button>
                        }
                    </div>
                    :
                    <></>
                  }
                </div>
                {
                  this.state.loaderView ?
                    <div className="loader">
                      <Loader
                        type="spinner-circle"
                        bgColor={"#b5b5b5"}
                        title={""}
                        color={"#b5b5b5"}
                        size={90}
                      />
                    </div>
                  :
                  <>
                  {
                    this.state.preferenceDomain === "All" ?
                        <>
                          {this.renderDomainData()}
                        </>
                      :
                        <>
                          {this.renderFilteredDomainData()}
                        </>
                    }
                  </>
                }
                <br/><br/>
              </div>
            :
              <>
                <div className="dashboard_body">
                  <div className="dashboard_body_marginsLR">
                    <p className="dashboard_body_div1_txt">Take the first step to Secure & Own their digital identity</p>
                    <p className="dashboard_body_div1_subtxt">Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity</p>
                  </div>
                  <div className="responsive_worldMap">
                    <Row style={{marginTop: "10%"}}>
                        <Col md={10} sx={9} xs={9}>
                            <input
                                className="dashboard_DomainInput"
                                type="text"
                                value={this.state.domain}
                                placeholder="Search for your name.crypto"
                                onChange={(e) => {this.handleDomainName(e)}}
                            />
                            {  
                                this.state.errors["domain"] ? (
                                    <span
                                        id="marginInputs_Dashboard"
                                        className="validateErrorTxt"
                                    >
                                        {this.state.errors["domain"]}
                                    </span>
                                ) :
                                (
                                    <div className="registerInputMargin"></div>
                                )
                            }
                        </Col>
                        <Col md={2} xs={3} sm={3}>
                          <button className="searchBtn" onClick={this.handleSearch}>
                            <FiSearch size={23} className="FiSearch-icon"/><span className="searchBtn_txt">Search</span>
                          </button>
                        </Col>                   
                    </Row>
                    <div className="domTags_margin">
                        <div className="domTags_first">
                          <p className="domTags_txt">.crypto</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.nft</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.x</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.wallet</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.bitcoin</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.dao</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.888</p>
                        </div>
                        <div className="domTags_middle">
                          <p className="domTags_txt">.zil</p>
                        </div>
                        <div className="domTags_right">
                          <p className="domTags_txt">.blockchain</p>
                        </div>
                      </div>
                    <div className="dashboard_body_LR_background"></div>
                  </div>
                </div>
                <div className="dashboard_body_2">
                  <p className="dashboard_body_div1_txt2">We Are Verity</p>
                  <p className="dashboard_body_div1_subtxt2">Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity Take the first step to Secure/Own their digital identity</p>
                  <div className="dashboard_body_2_margins">
                    <Row >
                      <Col md={6}>
                        <img src={M2} className="M2"/>
                        <p className="M2_txt">Domains Registered</p>
                        <img src={Hundred} className="Hundred"/>
                        <p className="M2_txt">Coins + Tokens Supported</p>
                        <img src={TwoHundred} className="Hundred"/>
                        <p className="M2_txt">Integrations</p>
                        <img src={Fourty} className="Hundred"/>
                        <p className="M2_txt">Partners</p>
                      </Col>
                      <Col md={6}>
                        <p className="Official_Partners">Official Partners</p>
                        <img src={unstoppable} className="unstoppable_blue"/>
                        <br clear="all"/>
                        <img src={persona} className="persona_home"/>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            }
        </div>
    );
  }
}

export default withRouter(Dashboard);
