import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoMail } from "react-icons/io5";
import {getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';
import {initializeApp} from 'firebase/app';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import axios from "axios";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { getFirestore, collection, addDoc  } from "firebase/firestore";

import wallet from "../../assets/wallet.png";
import google from "../../assets/google.png";
import twitter from "../../assets/twitter.png";
import discord from "../../assets/discord.png";
import linkedin from "../../assets/linkedin.png";
import check from "../../assets/check.png";
import proceed from "../../assets/proceed.png";
import Footer from "../../Components/Footer/footer";

const firebaseConfig = {
    apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
    authDomain: "verityverification0.firebaseapp.com",
    databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "verityverification0",
    storageBucket: "verityverification0.appspot.com",
    messagingSenderId: "709250129230",
    appId: "1:709250129230:web:321b332165e243328a521f",
    measurementId: "G-QX1DD7CMM7"
};

const client_id = "86g0s2hxcy95gp";
const client_secret = "KZkgMiQsH1mlM0DV"
const redirect_uri = "http://localhost:3000/linkedin"

const { chains, provider } = configureChains(
    [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    [alchemyProvider({ apiKey: 'obD9VwIIHamZFQ5I09pDi810_UWYN-5d' }), publicProvider()],
);

const { connectors } = getDefaultWallets({
    appName: "Verity Verification",
    chains
});

const wagmiClient = createClient({
    autoConnect: false,
    connectors,
    provider
});

const queryParams =  new URLSearchParams(window.location.search);

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchView: false,
      email: "",
      password: "",
      confirmPassword: "",
      fullName: "",
      errors:{},
      count: 0,
      warnToast: false
    };
    this.handleRegister = this.handleRegister.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.handleLinkedInLogin = this.handleLinkedInLogin.bind(this);
    this.handleLinkedInAccessToken = this.handleLinkedInAccessToken.bind(this);
    this.handleLinkedInEmail = this.handleLinkedInEmail.bind(this);
    this.handleLinkedInFirebase = this.handleLinkedInFirebase.bind(this);
  }
  handleName = (event) => {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>4) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must be at least 5 characters";
      this.setState({ errors: errors});
    }
    this.setState({
        fullName: event.target.value,
    });
  }
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  };
  handlePassword = (event) => {
    this.setState({
        password: event.target.value
    });
    let errors = this.state.errors;
    var password = this.state.password;
    var passwordLength = password.length;
    console.log("LENGTH ====>", passwordLength)
    if (passwordLength > 6) {
        errors["password"] = null
        this.setState({ errors: errors});
    }
    else {
        errors["password"] = "Must be 8 or more characters";
        this.setState({ errors: errors });
    }
  };
  handleConfirmPassword = (event) => {
    this.setState({
        confirmPassword: event.target.value
    });
    let errors = this.state.errors;
    var password = this.state.confirmPassword;
    var passwordLength = password.length;
    console.log("LENGTH ====>", passwordLength)
    if (passwordLength > 6) {
        errors["confirm_password"] = null
        this.setState({ errors: errors});
    }
    else {
        errors["confirm_password"] = "Must be 8 or more characters";
        this.setState({ errors: errors });
    }
  };
  navigateLogin(){
    this.props.history.push("/Login");
  }
  handleGoogleLogin = async => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const googleProvider = new GoogleAuthProvider();

    signInWithPopup(auth, googleProvider)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        let photoURL = user.photoURL;
        let new_photoURL = photoURL.replace(/s96-c/g, "s400-c");
        this.userFirestoreGeneral(user.uid, user.email, "google", user.displayName, new_photoURL)
        this.storeData(user);
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
        //this.setState({ isSignupState: false });
      })
  }
  handleLinkedInLogin = (code) => {
    console.log("Code ====>", code)
    const accessAPI = "https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code="+code+"&redirect_uri="+redirect_uri+"&client_id="+client_id+"&client_secret="+client_secret;
    axios({
            method: "post",
            url: accessAPI,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((response) => {
            this.handleLinkedInAccessToken(response.data.access_token);
            this.handleLinkedInEmail(response.data.access_token);
        },
        (error) => {
            console.log("");
        }
    );
  };
  handleLinkedInAccessToken = async (token) => {
    console.log("Access Token ====>", token);
    await axios({
            method: "GET",
            url: `https://api.linkedin.com/v2/me`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ` + token,
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
            },
        })
        .then((response) => {
            console.log("User Details ====>", response);
            let fullName = response.data.localizedFirstName + " " + response.data.localizedLastName
            this.setState({
                fullName: fullName
            })
        },
        (error) => {
            console.log("Error is ===>", error);
        }
    );
  }
  handleLinkedInEmail = async (token) => {
    const emailURL = `https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))`
    await axios({
        method: "GET",
        url: emailURL,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ` + token,
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
            },
        })
        .then((response) => {
            let emailID =""
            let parsedData = response.data.elements
            parsedData.forEach(function(obj) {
                console.log(obj['handle~'].emailAddress);
                emailID = obj['handle~'].emailAddress
            });
            this.setState({
                email: emailID
            })
            this.handleLinkedInFirebase(emailID);
        },
        (error) => {
            console.log("Error is ===>", error);
        }
    );
  }
  handleLinkedInFirebase = async (email) => {
    const password = "Abc@1234"
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app)
    let fullName = this.state.fullName

    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            console.log("Account Created with FIREBASE")
            const user = userCredential.user;
            updateProfile(userCredential.user, { displayName : fullName })
            setTimeout(() => {
                this.storeData(user);
                this.userFirestoreGeneral(user.uid, email, "linkedin", fullName, "")
            }, 2500);
        })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
        //this.setState({ isSignupState: false });
      })
  }
  storeData = async (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("<=====UserData Saved=====>");
  };
  async componentDidMount(){
    let warn = queryParams.get("warn")
    if(warn){
      this.setState({
        warnToast: true
      })
    }
    var userData = JSON.parse(localStorage.getItem('userData'));
    if(userData){
        this.props.history.push("/");
    }
  }
  handleRegister = async () => {
    const { email, password, fullName, confirmPassword } = this.state;
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    let emailWarning = this.state.errors["email"];
    let nameWarning = this.state.errors["name"];
    let passwordWarning = this.state.errors["password"];
    let confirmPasswordWarning = this.state.errors["confirm_password"];
    if(fullName != "" && email != "" && password != "" && confirmPassword != ""){
        if(emailWarning == null && nameWarning == null && passwordWarning == null && confirmPasswordWarning == null)
        {
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log("Account Created with FIREBASE")
                const user = userCredential.user;
                updateProfile(userCredential.user, { displayName : fullName })
                setTimeout(() => {
                this.storeData(user);
                this.userFirestoreGeneral(user.uid, email, "email", fullName, "")
                }, 2500);
            })
            .catch((error) => {
                console.log("Error IS ====>", error);
                let errors = {};
                errors["msg"] = "Authentication Failed";
                this.setState({ errors });
                setTimeout(() => {
                this.setState({ errors: {} });
                }, 3000);
                //this.setState({ isSignupState: false });
            })
        }
    }
  }
  handleWalletRegister = async (account) => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    let balanceSymbol = account.balanceSymbol
    let formattedSymbol = balanceSymbol.toLowerCase()
    let fullAddress = account.address
    let slicedAddress = formattedSymbol +"." + fullAddress.slice(0, 10) + "@rainbow.com"

    let password = "Abc@1234"
    createUserWithEmailAndPassword(auth, slicedAddress, password)
      .then((userCredential) => {
        console.log("Signed Up With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.userFirestoreWallet(user.uid, account, slicedAddress)
        this.storeData(user);
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
      })
  }
  userFirestoreWallet = async(uid, account, email) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    //const data = await getDocs(collection(db, "users"));
    //const fetchedData = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
    try {
        if(this.state.count === 0){
            const createUser = await addDoc(collection(db, "users"), {
                email: email,
                name: "",
                photoURL: "",
                isDiscordConnected: false,
                isGoogleConnected: false,
                isLinkedInConnected: false,
                isTwitterConnected: false,
                isUnstoppableConnected: false,
                isWalletConnected: true,
                userID: uid,
                walletAddress: account.address,
                walletBallance: account.displayBalance,
                walletChain: account.balanceSymbol,
                unstoppableDomain: "",
                otherWallets: []
            });
            this.setState({ count: 1})
            console.log("Document written with ID: ", createUser.id);
        }
        this.props.history.push("/Settings");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
  }
  userFirestoreGeneral = async(uid, email, social, name, photoURL) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    //const data = await getDocs(collection(db, "users"));
    //const fetchedData = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
    try {
        if(this.state.count === 0){
            const createUser = await addDoc(collection(db, "users"), {
                fullName: name,
                email: email,
                photoURL: photoURL,
                isDiscordConnected: social === "discord" ? true : false,
                isGoogleConnected: social === "google" ? true : false,
                isLinkedInConnected: social === "linkedin" ? true : false,
                isTwitterConnected: social === "twitter" ? true : false,
                isUnstoppableConnected: social === "unstoppable" ? true : false,
                isWalletConnected: false,
                userID: uid,
                walletAddress: "",
                walletBallance: "",
                walletChain: "",
                unstoppableDomain: "",
                otherWallets: []
            });
            this.setState({ count: 1})
            console.log("Document written with ID: ", createUser.id);
        }
        this.props.history.push("/Settings");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
  }
  render() {
    return (
      <>
        <div className="container-box_login">
            {
                this.state.switchView ?
                    <div className="signup_emailBox">
                        <p className="login_header_text">Create account</p>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <input
                                    className="emailInput"
                                    type="text"
                                    placeholder="Full name"
                                    onChange={this.handleName}
                                    onFocus={this.handleName}
                                    value={this.state.fullName}
                                />
                            </Col>
                        </Row>
                        {  
                            this.state.errors["name"] ? (
                                <span
                                    id="marginInputs"
                                    className="validateErrorTxt registerInputMargin"
                                >
                                    {this.state.errors["name"]}
                                </span>
                            ) :
                            (
                                <div className="registerInputMargin"></div>
                            )
                        }
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <input
                                    className="emailInput"
                                    type="text"
                                    placeholder="Email"
                                    onChange={this.handleEmail}
                                    onFocus={this.handleEmail}
                                    value={this.state.email}
                                />
                            </Col>
                        </Row>
                        {  
                            this.state.errors["email"] ? (
                                <span
                                    id="marginInputs"
                                    className="validateErrorTxt registerInputMargin"
                                >
                                    {this.state.errors["email"]}
                                </span>
                            ) :
                            (
                                <div className="registerInputMargin"></div>
                            )
                        }
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <input
                                    className="emailInput"
                                    type="password"
                                    placeholder="Password"
                                    onChange={this.handlePassword}
                                    onFocus={this.handlePassword}
                                    value={this.state.password}
                                />
                            </Col>
                        </Row>
                        {  
                            this.state.errors["password"] ? (
                                <span
                                    id="marginInputs"
                                    className="validateErrorTxt registerInputMargin"
                                >
                                    {this.state.errors["password"]}
                                </span>
                            ) :
                            (
                                <div className="registerInputMargin"></div>
                            )
                        }
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <input
                                    className="emailInput"
                                    type="password"
                                    placeholder="Confirm Password"
                                    onChange={this.handleConfirmPassword}
                                    onFocus={this.handleConfirmPassword}
                                    value={this.state.confirmPassword}
                                />
                            </Col>
                        </Row>
                        {  
                            this.state.errors["confirm_password"] ? (
                                <span
                                    id="marginInputs"
                                    className="validateErrorTxt registerInputMargin"
                                >
                                    {this.state.errors["confirm_password"]}
                                </span>
                            ) :
                            (
                                <div className="registerInputMargin"></div>
                            )
                        }
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <div className="login_button" onClick={() => this.handleRegister()}>
                                    <p className="login_signup_ques_text_white">Register</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                :
                <div className="login_containerBox_inner">
                    {
                        this.state.warnToast ?
                            <div className="warnToast">
                                <p className="warnToast_txt">Login/Register to checkout</p>
                            </div>
                        :
                        <></>
                    }
                    <p className="login_header_text">Create Account</p>
                    <WagmiConfig client={wagmiClient}>
                        <RainbowKitProvider chains={chains}>
                            <ConnectButton.Custom>
                                {({
                                    account,
                                    chain,
                                    openAccountModal,
                                    openChainModal,
                                    openConnectModal,
                                    authenticationStatus,
                                    mounted,
                                }) => {
                                    const ready = mounted && authenticationStatus !== 'loading';
                                    const connected =
                                    ready &&
                                    account &&
                                    chain &&
                                    (!authenticationStatus ||
                                    authenticationStatus === 'authenticated');
                                    return (
                                    <div
                                        {...(!ready && {
                                        'aria-hidden': true,
                                        'style': {
                                            opacity: 0,
                                            pointerEvents: 'none',
                                            userSelect: 'none',
                                        },
                                        })}
                                    >
                                        {(() => {
                                        if (!connected) {
                                            return (
                                            <div className="social_button" onClick={openConnectModal}>
                                                <Row>
                                                    <Col md={2} xs={2} sm={2}>
                                                        <img src={wallet} className="social_button_icon"/>
                                                    </Col>
                                                    <Col md={10} xs={10} sm={10}>
                                                        <span className="social_button_text">Connect Wallet</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            );
                                        }

                                        if (chain.unsupported) {
                                            return (
                                            <button onClick={openChainModal} type="button">
                                                Wrong network
                                            </button>
                                            );
                                        }
                                        return (
                                            <div className="social_button_connected" onClick={() => this.handleWalletRegister(account)}>
                                                <Row>
                                                    <Col md={1} xs={1} sm={1}>
                                                        <img src={check} className="social_button_icon"/>
                                                    </Col>
                                                    <Col md={10} xs={10} sm={10}>
                                                        <span className="social_button_text_white">Proceed with Wallet</span>
                                                    </Col>
                                                    <Col md={1} xs={1} sm={1}>
                                                        <img src={proceed} className="proceed_button_icon"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                        })()}
                                    </div>
                                    );
                                }}
                            </ConnectButton.Custom>
                        </RainbowKitProvider>
                    </WagmiConfig>
                    <div className="social_button" onClick={this.handleGoogleLogin}>
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <img src={google} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text">Continue with Google</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="social_button_disabled">
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <img src={twitter} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text">Continue with Twitter</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="social_button_disabled">
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <img src={discord} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text">Continue with Discord</span>
                            </Col>
                        </Row>
                    </div>
                    <LinkedIn
                        clientId="86g0s2hxcy95gp"
                        redirectUri={`${window.location.origin}/linkedin`}
                        scope="r_emailaddress,r_liteprofile"
                        onSuccess={(code) => {
                            this.handleLinkedInLogin(code);
                        }}
                    >
                        {({ linkedInLogin }) => (
                            <div className="social_button" onClick={linkedInLogin}>
                                <Row>
                                    <Col md={2} xs={2} sm={2}>
                                        <img src={linkedin} className="social_button_icon"/>
                                    </Col>
                                    <Col md={10} xs={10} sm={10}>
                                        <span className="social_button_text">Continue with LinkedIn</span>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </LinkedIn>
                    <div className="social_button_blue"  onClick={() => this.setState({switchView: true})}>
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <IoMail
                                size={25}
                                className="IoMail-icon"
                                />
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text_white">Continue with Email</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="hr"></div>
                    <p className="login_signup_ques_text">Already have an account?</p>
                    <div className="social_button"  onClick={() => this.navigateLogin()}>
                        <p className="login_signup_ques_text">Login</p>
                    </div>
                </div>
            }
        </div>
        <Footer/>
      </>
    )
  }
}
export default withRouter(Register);
