import React from "react";
import { withRouter } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { AiOutlineHome, AiOutlineSetting } from "react-icons/ai";
import { TfiWorld } from "react-icons/tfi";
import { FiShoppingBag, BiInfoCircle, BiUserCircle } from "react-icons/fi";
import { BsTools, BsPersonCircle } from "react-icons/bs";
import { SlLogin } from "react-icons/sl";

import appIcon from "../../assets/appIcon.png";
import "./custom.scss";
import "./sidebar_styles.css";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: this.props.sideBarShow,
      isLoggedIn: false
    };
  }
  componentDidMount = () =>{
    var userData = JSON.parse(localStorage.getItem('userData'));
    if(userData){
      this.setState({
          isLoggedIn: true
      })
    }
  }
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  navigateHome = () => {
    this.props.history.push("/");
    this.toggleButton()
  }
  navigateDomains = () => {
    this.props.history.push("/Domains");
    this.toggleButton()
  }
  navigateTools = () => {
    this.props.history.push("/comingSoon?page=TOOLS");
    this.toggleButton()
  }
  navigateMarketplace = () => {
    this.props.history.push("/comingSoon?page=MARKETPLACE");
    this.toggleButton()
  }
  navigateSettings= () => {
    this.props.history.push("/Settings");
    this.toggleButton()
  }
  navigateLogin= () => {
    this.props.history.push("/Login");
    this.toggleButton()
  }
  navigateRegister = () => {
    this.props.history.push("/Register");
    this.toggleButton()
  }
  render() {
    return (
      <>
        {
          this.state.sideBar?
            <ProSidebar>
              <center>
                <img src={appIcon} style={{ height: 120, marginTop:"17%", marginBottom: "10%" }} />
              </center>
              <Menu iconShape="square" className="sidebar-icons-margin">
                <MenuItem icon={<AiOutlineHome size={32} color={"#fff"} style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateHome} className="sidebar-item-txt">Home</span>
                </MenuItem>
                <MenuItem icon={<TfiWorld size={25} color={"#fff"} style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateDomains} className="sidebar-item-txt">Domains</span>
                </MenuItem>
                <MenuItem icon={<BsTools size={24} color="white" style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateTools} className="sidebar-item-txt">Tools</span>
                </MenuItem>
                <MenuItem icon={<FiShoppingBag size={26} color={"#fff"} style={{marginRight: 10}}/>}>
                  <span onClick={this.navigateMarketplace} className="sidebar-item-txt">Marketplace</span>
                </MenuItem>
                {
                  this.state.isLoggedIn ?
                    <MenuItem icon={<AiOutlineSetting size={27} color={"#fff"} style={{marginRight: 10}}/>}>
                      <span onClick={this.navigateSettings} className="sidebar-item-txt">Settings</span>
                    </MenuItem>
                  :
                  <></>
                }
                {
                  this.state.isLoggedIn ?
                  <></>
                  :
                  <>
                  <hr className="sidebar_divider"></hr>
                  <MenuItem icon={<SlLogin size={23} color={"#fff"} style={{marginRight: 7}}/>}>
                    <span onClick={this.navigateLogin} className="sidebar-item-txt">Login</span>
                  </MenuItem>
                  <MenuItem icon={<BsPersonCircle size={25} color={"#fff"} style={{marginRight: 7}}/>}>
                    <span onClick={this.navigateRegister} className="sidebar-item-txt">Register</span>
                  </MenuItem>
                  </>
                }
                <div className="empty-sidebar-div"></div>
              </Menu>
            </ProSidebar>
          :
            <></>
        }
      </>
    );
  }
}

export default withRouter(SideBar);
