import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoMail } from "react-icons/io5";
import {getAuth, signInWithEmailAndPassword, GoogleAuthProvider, TwitterAuthProvider, signInWithPopup} from 'firebase/auth';
import {initializeApp} from 'firebase/app';
import { collection, query, where, getDocs, getFirestore, doc, setDoc } from "firebase/firestore";
import { LinkedIn } from 'react-linkedin-login-oauth2';
import axios from "axios";
import UAuth from '@uauth/js'
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import check from "../../assets/check.png";
import proceed from "../../assets/proceed.png";
import wallet from "../../assets/wallet.png";
import google from "../../assets/google.png";
import twitter from "../../assets/twitter.png";
import ud from "../../assets/ud-btn.png";
import discord from "../../assets/discord.png";
import linkedin from "../../assets/linkedin.png";

import Footer from "../../Components/Footer/footer";
import "./Login.css";
import "@rainbow-me/rainbowkit/styles.css";

const firebaseConfig = {
    apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
    authDomain: "verityverification0.firebaseapp.com",
    databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "verityverification0",
    storageBucket: "verityverification0.appspot.com",
    messagingSenderId: "709250129230",
    appId: "1:709250129230:web:321b332165e243328a521f",
    measurementId: "G-QX1DD7CMM7"
};

const client_id = "86g0s2hxcy95gp";
const client_secret = "KZkgMiQsH1mlM0DV"
const redirect_uri = "http://localhost:3000/linkedin"


const uauth = new UAuth({
    clientID: "42724027-c920-4827-b45c-e231bedca686",
    redirectUri: "http://localhost:3000",
    scope: "openid wallet"
})


const { chains, provider } = configureChains(
    [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    [alchemyProvider({ apiKey: 'obD9VwIIHamZFQ5I09pDi810_UWYN-5d' }), publicProvider()],
);

const { connectors } = getDefaultWallets({
    appName: "Verity Verification",
    chains
});

const wagmiClient = createClient({
    autoConnect: false,
    connectors,
    provider
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchView: false,
      email: null,
      password: "",
      errors: {},
      users: [],
      unstoppableUser: false,
      isLoading: false,
      unstoppableAuthorization: null,
      count: 0
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLinkedInLogin = this.handleLinkedInLogin.bind(this);
    this.handleLinkedInAccessToken = this.handleLinkedInAccessToken.bind(this);
    this.handleLinkedInEmail = this.handleLinkedInEmail.bind(this);
    this.handleLinkedInFirebase = this.handleLinkedInFirebase.bind(this);
    this.handleUnstoppableLogin = this.handleUnstoppableLogin.bind(this);
  }
  navigateRegister(){
    this.props.history.push("/Register");
  }
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  };
  handlePassword = (event) => {
    this.setState({
        password: event.target.value
    });
    let errors = this.state.errors;
    var password = this.state.password;
    var passwordLength = password.length;
    console.log("LENGTH ====>", passwordLength)
    if (passwordLength > 1) {
        this.setState({ errors: errors});
    }
    else {
        errors["password"] = "Cannot be empty";
        this.setState({ errors: errors });
    }
  };
  handleLogin = async () => {
    //this.setState({ isSignupState: true });
    const { email, password } = this.state;
    console.log(email)
    console.log(password)

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.storeData(user);
        this.props.history.push("/Profile");
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
        //this.setState({ isSignupState: false });
      })
  }
  handleGoogleLogin = async => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const googleProvider = new GoogleAuthProvider();

    signInWithPopup(auth, googleProvider)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.storeData(user);
        this.props.history.push("/Profile");
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
        //this.setState({ isSignupState: false });
      })
  }
  handleLinkedInLogin = (code) => {
    console.log("Code ====>", code)
    const accessAPI = "https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code="+code+"&redirect_uri="+redirect_uri+"&client_id="+client_id+"&client_secret="+client_secret;
    axios({
            method: "post",
            url: accessAPI,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((response) => {
            this.handleLinkedInAccessToken(response.data.access_token);
            this.handleLinkedInEmail(response.data.access_token);
        },
        (error) => {
            console.log("");
        }
    );
  };
  handleLinkedInAccessToken = async (token) => {
    console.log("Access Token ====>", token);
    await axios({
            method: "GET",
            url: `https://api.linkedin.com/v2/me`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ` + token,
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
            },
        })
        .then((response) => {
            console.log("User Details ====>", response);
        },
        (error) => {
            console.log("Error is ===>", error);
        }
    );
  }
  handleLinkedInEmail = async (token) => {
    const emailURL = `https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))`
    await axios({
        method: "GET",
        url: emailURL,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ` + token,
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
            },
        })
        .then((response) => {
            let emailID =""
            let parsedData = response.data.elements
            parsedData.forEach(function(obj) {
                console.log(obj['handle~'].emailAddress);
                emailID = obj['handle~'].emailAddress
            });
            this.setState({
                email: emailID
            })
            this.handleLinkedInFirebase(emailID);
        },
        (error) => {
            console.log("Error is ===>", error);
        }
    );
  }
  handleLinkedInFirebase = async (email) => {
    const password = "Abc@1234"
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.storeData(user);
        this.props.history.push("/Profile");
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
        //this.setState({ isSignupState: false });
      })
  }
  storeData = async (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("<=====UserData Saved=====>");
  };
  async componentDidMount(){
    var userData = JSON.parse(localStorage.getItem('userData'));
    if(userData){
        this.props.history.push("/");
    }
  }
  handleUnstoppableLogin = () => {
    uauth
      .loginWithPopup()
      .then(() => uauth.user().then((setUser) => this.setState({unstoppableUser: true})))
      .then(() => uauth.user().then((setUser) => this.handleUnstoppableFirebase(setUser)))
      .then(() => uauth.user().then((setUser) => console.log(setUser)))
      .catch(console.error)
      .finally(() => this.setState({isLoading: false}))
  }
  handleUnstoppableFirebase = (data) => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    let fullAddress = data.wallet_address
    console.log(fullAddress)
    let slicedAddress = "eth" + "." + fullAddress.slice(0, 10) + "@rainbow.com"
    let password = "Abc@1234"

    signInWithEmailAndPassword(auth, slicedAddress, password)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.storeData(user);
        this.domainUpdate(slicedAddress, data.sub)
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
      })
  }
  domainUpdate = async (email, domain) => {
    let docID = ""
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    console.log(docID)
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { unstoppableDomain: domain }, { merge: true });
    this.props.history.push("/Profile");
  }
  handleTwitterLogin = () => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const twitterProvider = new TwitterAuthProvider();

    signInWithPopup(auth, twitterProvider)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.storeData(user);
        this.props.history.push("/Profile");
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
        //this.setState({ isSignupState: false });
      })
  }
  handleWalletLogin = async (account) => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    let balanceSymbol = account.balanceSymbol
    let formattedSymbol = balanceSymbol.toLowerCase()
    let fullAddress = account.address
    let slicedAddress = formattedSymbol +"." + fullAddress.slice(0, 10) + "@rainbow.com"

    let password = "Abc@1234"
    signInWithEmailAndPassword(auth, slicedAddress, password)
      .then((userCredential) => {
        console.log("Signed In With FIREBASE")
        const user = userCredential.user;
        console.log(user);
        this.storeData(user);
        this.props.history.push("/Profile");
      })
      .catch((error) => {
        console.log("Error IS ====>", error);
        let errors = {};
        errors["msg"] = "Authentication Failed";
        this.setState({ errors });
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 3000);
      })
  }
  forgotPasswordNavigate = () => {
    this.props.history.push("/ForgotPassword");
  }
  render() {
    return (
        <>
            <div className="container-box_login">
            {
                this.state.switchView ?
                <div className="login_emailBox">
                    <p className="login_header_text">Login</p>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <input
                                className="emailInput"
                                type="text"
                                placeholder="Email"
                                onChange={(e) => {this.handleEmail(e)}}
                                value={this.state.email}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <input
                                className="emailInput"
                                type="password"
                                placeholder="Password"
                                onChange={(e) => {this.handlePassword(e)}}
                                value={this.state.password}
                            />
                            <p className="login_ForgotPasswordText" onClick={this.forgotPasswordNavigate}>Forgot Password?</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <div className="login_button" onClick={() => this.handleLogin()}>
                                <p className="login_signup_ques_text_white">Log In</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                :
                <div className="login_containerBox_inner">
                    <p className="login_header_text">Login</p>
                    <WagmiConfig client={wagmiClient}>
                        <RainbowKitProvider chains={chains}>
                            <ConnectButton.Custom>
                                {({
                                    account,
                                    chain,
                                    openAccountModal,
                                    openChainModal,
                                    openConnectModal,
                                    authenticationStatus,
                                    mounted,
                                }) => {
                                    const ready = mounted && authenticationStatus !== 'loading';
                                    const connected =
                                    ready &&
                                    account &&
                                    chain &&
                                    (!authenticationStatus ||
                                    authenticationStatus === 'authenticated');
                                    return (
                                    <div
                                        {...(!ready && {
                                        'aria-hidden': true,
                                        'style': {
                                            opacity: 0,
                                            pointerEvents: 'none',
                                            userSelect: 'none',
                                        },
                                        })}
                                    >
                                        {(() => {
                                        if (!connected) {
                                            return (
                                            <div className="social_button" onClick={openConnectModal}>
                                                <Row>
                                                    <Col md={2} xs={2} sm={2}>
                                                        <img src={wallet} className="social_button_icon"/>
                                                    </Col>
                                                    <Col md={10} xs={10} sm={10}>
                                                        <span className="social_button_text">Connect Wallet</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            );
                                        }

                                        if (chain.unsupported) {
                                            return (
                                            <button onClick={openChainModal} type="button">
                                                Wrong network
                                            </button>
                                            );
                                        }
                                        return (
                                            <div className="social_button_connected" onClick={() => this.handleWalletLogin(account)}>
                                                <Row>
                                                    <Col md={1} xs={1} sm={1}>
                                                        <img src={check} className="social_button_icon"/>
                                                    </Col>
                                                    <Col md={10} xs={10} sm={10}>
                                                        <span className="social_button_text_white">Proceed with Wallet</span>
                                                    </Col>
                                                    <Col md={1} xs={1} sm={1}>
                                                        <img src={proceed} className="proceed_button_icon"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                        })()}
                                    </div>
                                    );
                                }}
                            </ConnectButton.Custom>
                        </RainbowKitProvider>
                    </WagmiConfig>
                    {
                        this.state.unstoppableUser ?
                        <div className="social_button_connected">
                            <Row>
                                <Col md={1} xs={1} sm={1}>
                                    <img src={check} className="social_button_icon"/>
                                </Col>
                                <Col md={10} xs={10} sm={10}>
                                    <span className="social_button_text_white">Continue with Unstoppable</span>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div className="social_button" onClick={this.handleUnstoppableLogin}>
                            <Row>
                                <Col md={2} xs={2} sm={2}>
                                    <img src={ud} className="social_button_icon"/>
                                </Col>
                                <Col md={10} xs={10} sm={10}>
                                    <span className="social_button_text">Continue with Unstoppable</span>
                                </Col>
                            </Row>
                        </div>
                    }
                    
                    <div className="social_button" onClick={this.handleGoogleLogin}>
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <img src={google} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text">Continue with Google</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="social_button" onClick={this.handleTwitterLogin}>
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <img src={twitter} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text">Continue with Twitter</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="social_button_disabled">
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <img src={discord} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text">Continue with Discord</span>
                            </Col>
                        </Row>
                    </div>
                    <LinkedIn
                        clientId="86g0s2hxcy95gp"
                        redirectUri={`${window.location.origin}/linkedin`}
                        scope="r_emailaddress,r_liteprofile"
                        onSuccess={(code) => {
                            this.handleLinkedInLogin(code);
                        }}
                    >
                        {({ linkedInLogin }) => (
                            <div className="social_button" onClick={linkedInLogin}>
                                <Row>
                                    <Col md={2} xs={2} sm={2}>
                                        <img src={linkedin} className="social_button_icon"/>
                                    </Col>
                                    <Col md={10} xs={10} sm={10}>
                                        <span className="social_button_text">Continue with LinkedIn</span>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </LinkedIn>
                    <div className="social_button_blue"  onClick={() => this.setState({switchView: true})}>
                        <Row>
                            <Col md={2} xs={2} sm={2}>
                                <IoMail
                                size={25}
                                className="IoMail-icon"
                                />
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text_white">Continue with Email</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="hr"></div>
                    <p className="login_signup_ques_text">Don't have an account?</p>
                    <div className="social_button" onClick={() => this.navigateRegister()}>
                        <p className="login_signup_ques_text">Register</p>
                    </div>
                </div>
            }
            </div>
            <Footer/>
        </>
      
    )
  }
}
export default withRouter(Login);
