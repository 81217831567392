import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlinePlus } from "react-icons/ai";
import {initializeApp} from 'firebase/app';
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { collection, query, where, getDocs, getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";

import user_img from "../assets/user_img.png";
import wallet from "../assets/wallet_white.png";
import google from "../assets/google.png";
import twitter from "../assets/twitter.png";
import discord from "../assets/discord.png";
import linkedin from "../assets/linkedin.png";
import "./Profile.css";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
    apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
    authDomain: "verityverification0.firebaseapp.com",
    databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "verityverification0",
    storageBucket: "verityverification0.appspot.com",
    messagingSenderId: "709250129230",
    appId: "1:709250129230:web:321b332165e243328a521f",
    measurementId: "G-QX1DD7CMM7"
};
const ALCHEMY_ID = "obD9VwIIHamZFQ5I09pDi810_UWYN-5d"
const { chains, provider } = configureChains(
    [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    [alchemyProvider({ apiKey: 'obD9VwIIHamZFQ5I09pDi810_UWYN-5d' }), publicProvider()],
);

const { connectors } = getDefaultWallets({
    appName: "Verity Verification",
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      photoURL: null,
      image: null,
      fullName: null,
      imgUrl: null,
      userData: {},
      errors: {},
      random: Math.floor(Math.random() * 100000000) + 1,
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handleName = this.handleName.bind(this);
    this.saveData = this.saveData.bind(this);
  }
  navigateDashboard(){
    this.props.history.push("/");
  }
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  };
  handleName = (event) => {
    this.setState({
        fullName: event.target.value,
      });
  }
  async componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    var userData = JSON.parse(localStorage.getItem('userData'));
    let email = "";
    let data = {}
    if(userData){
        email= userData.email
    }
    else{
        this.props.history.push("/");
    }
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data=doc.data()
        this.setState({
            userData: data,
            photoURL: data.photoURL,
            fullName: data.fullName
        })
    });
    
  }
  saveData = async () => {
    const { image, fullName } = this.state;
    this.toastFunct()
    if(image){
        this.uploadImage();
    }
    let docID = ""
    let email = this.state.userData.email
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { fullName: fullName }, { merge: true });
    this.successToast();
  }
  handleImage = async (event) => {
    let value = URL.createObjectURL(event.target.files[0]);
    this.setState({
        photoURL: value,
        image: event.target.files[0]
    })
    
  }
  uploadImage = async() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    let file = this.state.image;
    let imageName = 'image-' + this.state.random;
    const storageRef = ref(storage, imageName);
    await uploadBytes(storageRef, file)
    .then(() => {
        getDownloadURL(storageRef).then((url)=> {
            this.setState({
                imgUrl: url
            })
            this.updateImageData(url)
        })
    })
  }
  updateImageData = async(url) => {
    const app = initializeApp(firebaseConfig);
    let docID = ""
    let email = this.state.userData.email
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    console.log(url)
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { photoURL: url }, { merge: true });
  }
  toastFunct() {
    toast.info("Updating ..", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success('Profile Updated Successfully', {
      position: "bottom-center",
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  render() {
    return (
      <div className="profile-container-box">
          <div className="profile_body">
            <center>
                <div>
                    {
                        this.state.photoURL != "" ?
                        <>
                            <img src={this.state.photoURL} className="profile_image"/>
                            <label id="pic1">
                                <input
                                    type="file"
                                    name="fileToUpload"
                                    id="fileToUpload"
                                    size="1"
                                    accept="image/*"
                                    onChange={(event) => {this.handleImage(event)}}
                                />
                                <AiOutlinePlus
                                    size={23}
                                    className="IoMail-icon"
                                />
                            </label>
                        </>
                        :
                        <>
                            <img src={user_img} className="profile_image_empty"/>
                            <label id="pic1">
                                <input
                                    type="file"
                                    name="fileToUpload"
                                    id="fileToUpload"
                                    size="1"
                                    accept="image/*"
                                    onChange={(event) => {this.handleImage(event)}}
                                />
                                <AiOutlinePlus
                                    size={23}
                                    className="IoMail-icon"
                                />
                            </label>
                        </>
                    }
                    
                </div>
                <p className="profile_below_image_txt">The profile picture will be verified with the facial recogination technology identified on the ID card with the help of our FaceAPI.</p>
                <input
                    className="profile_emailInput"
                    type="text"
                    value={this.state.fullName}
                    placeholder="Full Name"
                    onChange={(e) => {this.handleName(e)}}
                />
                <input
                    className="profile_emailInput"
                    type="text"
                    disabled={true}
                    placeholder="Email"
                    onChange={(e) => {this.handleEmail(e)}}
                    value={this.state.userData.email}
                />
                <br/> <br/>
                <div>
                    <WagmiConfig client={wagmiClient}>
                        <RainbowKitProvider chains={chains}>
                        <ConnectButton.Custom>
                            {({
                                account,
                                chain,
                                openAccountModal,
                                openChainModal,
                                openConnectModal,
                                authenticationStatus,
                                mounted,
                            }) => {
                                // Note: If your app doesn't use authentication, you
                                // can remove all 'authenticationStatus' checks
                                const ready = mounted && authenticationStatus !== 'loading';
                                const connected =
                                ready &&
                                account &&
                                chain &&
                                (!authenticationStatus ||
                                    authenticationStatus === 'authenticated');

                                return (
                                <div
                                    {...(!ready && {
                                    'aria-hidden': true,
                                    'style': {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        userSelect: 'none',
                                    },
                                    })}
                                >
                                    {(() => {
                                    if (!connected) {
                                        return (
                                        <div className="profile_social_button"  onClick={openConnectModal}>
                                            <Row>
                                                <Col md={1} xs={1} sm={1}>
                                                    <img src={wallet} className="social_button_icon"/>
                                                </Col>
                                                <Col md={10} xs={10} sm={10}>
                                                    <span className="social_button_text_white">Connect Wallet</span>
                                                </Col>
                                            </Row>
                                        </div>
                                        );
                                    }

                                    if (chain.unsupported) {
                                        return (
                                        <button onClick={openChainModal} type="button">
                                            Wrong network
                                        </button>
                                        );
                                    }

                                    return (
                                        <div className="profile_social_button_connected">
                                            <Row>
                                                <Col md={1} xs={1} sm={1}>
                                                    <img src={wallet} className="social_button_icon"/>
                                                </Col>
                                                <Col md={11} xs={11} sm={11} onClick={openChainModal}>
                                                    <span className="social_button_text_white">{account.balanceSymbol} Wallet Connected</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                    })()}
                                </div>
                                );
                            }}
                            </ConnectButton.Custom>
                        </RainbowKitProvider>
                    </WagmiConfig>
                    {
                        this.state.userData.isGoogleConnected ?
                        <>
                            <div className="profile_social_button_connected">
                                <Row>
                                    <Col md={1} xs={1} sm={1}>
                                        <img src={google} className="social_button_icon"/>
                                    </Col>
                                    <Col md={10} xs={10} sm={10}>
                                        <span className="social_button_text_white">Google Connected</span>
                                    </Col>
                                </Row>
                            </div>
                        </>
                        :
                        <>
                            <div className="profile_social_button">
                                <Row>
                                    <Col md={1} xs={1} sm={1}>
                                        <img src={google} className="social_button_icon"/>
                                    </Col>
                                    <Col md={10} xs={10} sm={10}>
                                        <span className="social_button_text_white">Continue with Google</span>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                    
                    <div className="profile_social_button">
                        <Row>
                            <Col md={1} xs={1} sm={1}>
                                <img src={twitter} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text_white">Continue with Twitter</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="profile_social_button">
                        <Row>
                            <Col md={1} xs={1} sm={1}>
                                <img src={discord} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text_white">Continue with Discord</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="profile_social_button">
                        <Row>
                            <Col md={1} xs={1} sm={1}>
                                <img src={linkedin} className="social_button_icon"/>
                            </Col>
                            <Col md={10} xs={10} sm={10}>
                                <span className="social_button_text_white">Continue with LinkedIn</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="profile_save_btn" onClick={() => this.saveData()}>
                        <p className="profile_save_btn_txt">Save changes</p>
                    </div>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </center>
          </div>
      </div>
    )
  }
}
export default withRouter(Profile);
