import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from './Components/Navbar/index';
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import Profile from "./Profile/Profile";
import Dashboard from "./Dashboard/Dashboard";
import Settings from "./Settings/Settings";
import ForgotPassword from "./Auth/ForgotPassword/ForgotPassword";
import Cart from "./Cart/Cart";
import Domains from "./Domains/Domains";
import FreeDomains from "./FreeDomains/FreeDomains";
import Stripe from "./Checkout/Stripe/Stripe";
import comingSoon from "./comingSoon";
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  render() {
    return (
      <Router forceRefresh={true}>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/Login" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/Profile" component={Profile} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route exact path="/linkedin" component={LinkedInCallback} />
          <Route path="/Settings" component={Settings} />
          <Route path="/Cart" component={Cart} />
          <Route path="/Domains" component={Domains} />
          <Route path="/FreeDomains" component={FreeDomains} />
          <Route path="/Stripe" component={Stripe} />
          <Route path="/comingSoon" component={comingSoon} />
        </Switch>
      </Router>
    );
  }
}

export default App;