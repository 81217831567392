import React from "react";
import { withRouter } from "react-router-dom";

import Footer from "./Components/Footer/footer";
import "./comingSoon.css";

const queryParams =  new URLSearchParams(window.location.search);

class comingSoon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: "",
        }
    }
    async componentDidMount(){
        let warn = queryParams.get("page")
        if(warn){
          this.setState({
            pageName: warn
          })
        }
      }
  render() {
    return (
        <>
            <div className="comingSoon">
                <p className="comingSoon_txt">{this.state.pageName} COMING SOON</p>
            </div>
            <Footer/>
        </>
    );
  }
}

export default withRouter(comingSoon);
