import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {initializeApp} from 'firebase/app';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import "./ForgotPassword.css";

const firebaseConfig = {
  apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
  authDomain: "verityverification0.firebaseapp.com",
  databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "verityverification0",
  storageBucket: "verityverification0.appspot.com",
  messagingSenderId: "709250129230",
  appId: "1:709250129230:web:321b332165e243328a521f",
  measurementId: "G-QX1DD7CMM7"
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchView: false,
      email: "",
      errors: {}
    };
    this.handleEmail = this.handleEmail.bind(this);
  }
  handleEmail = (event) => {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  };
  sendEmailLink = async () => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    let email = this.state.email
    await sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("Email Sent")
    })
    .catch((error) => {
      console.log("Error ====> ", error)
    });
  }
  render() {
    return (
      <div className="container-box">
        <center>
            <div className="forgotPassword_main">
                <p className="forgotPassword_Title">Reset Password</p>
                <p className="forgotPassword_TitleEmail">Email</p>
                <input
                    className="forgotPassword_emailInput"
                    type="text"
                    placeholder="demo@example.com"
                    onChange={this.handleEmail}
                    onFocus={this.handleEmail}
                    value={this.state.email}
                />
                {  
                    this.state.errors["email"] ? (
                        <span
                            id="marginInputsForgotPassword"
                            className="validateErrorTxt registerInputMargin"
                        >
                            {this.state.errors["email"]}
                        </span>
                    ) :
                    (
                        <div className="registerInputMargin"></div>
                    )
                }
                <div className="forgotPassword_login_button" onClick={() => this.sendEmailLink()}>
                    <p className="login_signup_ques_text_white">Send Recovery Link</p>
                </div>
                <hr/>
                <p className="forgotPassword_Back">Back</p>
            </div>
        </center>
      </div>
    )
  }
}
export default withRouter(ForgotPassword);
