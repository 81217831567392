import React from "react";
import { withRouter, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    getAuth,
    updatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
  } from 'firebase/auth';
import {initializeApp} from 'firebase/app';
import { collection, query, where, getDocs, getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { TfiSave } from "react-icons/tfi";
import Carousel from 'react-multi-carousel';


import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import {
    injectedWallet,
    rainbowWallet,
    metaMaskWallet,
    coinbaseWallet,
    walletConnectWallet,
    argentWallet,
    braveWallet,
    ledgerWallet,
    trustWallet 
  } from '@rainbow-me/rainbowkit/wallets';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import axios from "axios";

import setting_wallet from "../assets/setting_wallet_black.png";
import user_img from "../assets/user_img.png";
import profile_humanity from "../assets/profile_humanity.png";
import profile_location from "../assets/profile_location.png";
import profile_mail from "../assets/profile_mail.png";
import ethereum from "../assets/ethereum.png";
import matic from "../assets/matic.png";
import optimism from "../assets/optimism.png";
import arbitrum from "../assets/arbitrum.jpeg";
import primary from "../assets/primary.png";
import ud_nft from "../assets/ud_nft.jpg";

import Footer from "../Components/Footer/footer";
import "./Settings.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-multi-carousel/lib/styles.css';

const firebaseConfig = {
    apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
    authDomain: "verityverification0.firebaseapp.com",
    databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "verityverification0",
    storageBucket: "verityverification0.appspot.com",
    messagingSenderId: "709250129230",
    appId: "1:709250129230:web:321b332165e243328a521f",
    measurementId: "G-QX1DD7CMM7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const { chains, provider } = configureChains(
    [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    [alchemyProvider({ apiKey: 'obD9VwIIHamZFQ5I09pDi810_UWYN-5d' }), publicProvider()],
);
const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({ chains }),
        rainbowWallet({ chains }),
        metaMaskWallet({ chains }),
        coinbaseWallet({ chains, appName: 'My RainbowKit App' }),
        walletConnectWallet({ chains })
      ],
    },
    {
      groupName: 'Others',
      wallets: [
        argentWallet({ chains }),
        braveWallet({chains}),
        ledgerWallet({chains}),
        trustWallet({chains})
      ],
    },
]);
const wagmiClient = createClient({
    autoConnect: false,
    connectors,
    provider
});

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      paritialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1199, min: 699 },
      items: 3,
      paritialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 2,
      paritialVisibilityGutter: 0,
    },
  };

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            firestoreUserData : {},
            isPhotoAvailable: false,
            photoURL: null,
            fullName: "",
            profileView: false,
            passwordView: false,
            walletView: true,
            securityView: false,
            notificationView: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            email: "",
            ethAddress: "",
            unstoppableAddress:"john.nft",
            slicedWallet: "",
            preferredView : "Profile",
            isSocialAccountConnected: false,
            isGoogleConnected: false,
            isLinkedInConnected: false,
            editInfoView: false,
            image: null,
            imgUrl: null,
            random: Math.floor(Math.random() * 100000000) + 1,
            nftData: [],
            noNFT: true
        };
        this.handleOldPassword = this.handleOldPassword.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.toastFunct = this.toastFunct.bind(this);
        this.successToast = this.successToast.bind(this);
        this.profileEditToast = this.profileEditToast.bind(this);
        this.errorToast = this.errorToast.bind(this);
        this.saveData = this.saveData.bind(this);
    }
  async componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    auth.onAuthStateChanged((user) => {
        if (user) {
          this.setState({
            userData: user
          })
        }
    });

    var userData = JSON.parse(localStorage.getItem('userData'));
    let email = "";
    let data = {}
    if(userData){
        email= userData.email
        this.setState({
            email: userData.email
        })
    }
    else{
        this.props.history.push("/");
    }
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data=doc.data()
        this.setState({
            userData: data,
            photoURL: data.photoURL,
            fullName: data.fullName,
            isGoogleConnected: data.isGoogleConnected,
            isLinkedInConnected: data.isLinkedInConnected
        })
        if(data.isGoogleConnected || data.isLinkedInConnected){
            this.setState({
                isSocialAccountConnected: true
            })
        }
    });

    setTimeout(() => {
        let walletAddress = this.state.userData.walletAddress;
        let startingWA = walletAddress.slice(0, 5);
        let endingWA = walletAddress.substr(-5);
        console.log(startingWA+"..."+endingWA)
        this.setState({
            slicedWallet: startingWA+"..."+endingWA
        })
        this.handleNFTDomains();
      }, 2000);
  }
  getData = async () => {
    let docID = ""
    let email = this.state.email
    console.log(email)
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc._document.data.value.mapValue.fields
    });
    console.log(docID)
    this.setState({
        firestoreUserData: docID
    })
    if(docID.walletChain.stringValue === "ETH")
    {
        this.setState({
            ethAddress: docID.walletAddress.stringValue
        })
    }
  }
  handleOldPassword = (event) => {
    this.setState({
        oldPassword: event.target.value
    });
  }
  handleName = (event) => {
    this.setState({
        fullName: event.target.value
    });
  }
  handleNewPassword = (event) => {
    this.setState({
        newPassword: event.target.value
    });
  }
  handleConfirmPassword = (event) => {
    this.setState({
        confirmPassword: event.target.value
    });
  }
  resetPassword = async () => {
    this.toastFunct();
    const {oldPassword, newPassword, confirmPassword, email} = this.state;
    console.log(oldPassword, newPassword, confirmPassword);
    const credential = EmailAuthProvider.credential(
        email,
        oldPassword
    );
    await reauthenticateWithCredential(auth.currentUser, credential)
    .then((response) => {
        console.log(response);
        this.successToast();
        this.setState({
            oldPassword: "",
            newPassword:"",
            confirmPassword: "",
            passwordView: false
        })
    })
    .catch((error) => {
        console.log("Error IS ====>", error);
        this.errorToast();
    });
    await updatePassword(auth.currentUser, newPassword);
  }
  toastFunct() {
    toast.info("Updating ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Password Updated Successfully", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  profileEditToast() {
    toast.success("Profile Updated Successfully", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  errorToast() {
    toast.error("Auth Error/Wrong Current Password", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  handleWalletLogin = async (account) => {
    let walletAddress = account.address;
    let balanceSymbol = account.balanceSymbol;
    let docID = ""
    const app = initializeApp(firebaseConfig);
    let email = this.state.email;
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    const userRef = doc(db, "users", docID);
    if(this.state.userData.walletAddress){
        let otherWallets = this.state.userData.otherWallets;
        let alreadyLinked = otherWallets.some( otherWallets => otherWallets['address'] === walletAddress )
        if(alreadyLinked || this.state.userData.walletAddress === walletAddress){
            this.warnToast()
        }
        else{
            otherWallets.push(
                {
                    "address": walletAddress,
                    "chain" : balanceSymbol
                }
            )
            await setDoc(userRef, { otherWallets: otherWallets }, { merge: true });
            window.location.reload();
        }
    }
    else{
        await setDoc(userRef, { walletAddress: walletAddress, isWalletConnected: true, walletChain: balanceSymbol }, { merge: true });
        window.location.reload();
    }
  }
  warnToast(){
    toast.warn('Wallet address already linked', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  }
  otherWalletRender = () => {
    let otherWallets = this.state.userData.otherWallets;
    return(
        otherWallets.map((item, key) =>(
            <div className="wallet_greyTab">
                <Row>
                    <Col md={2}>
                        {
                            item.chain === "ETH" ?
                                <img src={ethereum} className="wallets_chain_icons" />
                            :
                            <></>
                        }
                        {
                            item.chain === "MATIC" ?
                                <img src={matic} className="wallets_chain_icons" />
                            :
                            <></>
                        }
                    </Col>
                    <Col md={10}>
                        {this.trimWalletAddress(item.address)}
                    </Col>
                </Row>
            </div>
        ))
    )
  }
  trimWalletAddress = (address) => {
    let walletAddress = address;
    let startingWA = walletAddress.slice(0, 5);
    let endingWA = walletAddress.substr(-5);
    let slicedAddress = startingWA+"..."+endingWA;
    return(
        <p className="wallet_greyTabTxt">{slicedAddress}<FaRegCopy size={14} className="FaRegCopy-icon" onClick={() => {navigator.clipboard.writeText(address)}}/></p>
    )
  }
  saveData = async () => {
    const { image, fullName } = this.state;
    this.toastFunct()
    if(image){
        this.uploadImage();
    }
    let docID = ""
    let email = this.state.userData.email
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { fullName: fullName }, { merge: true });
    this.profileEditToast();
    this.setState({
        editInfoView: false
    })
  }
  handleImage = async (event) => {
    let value = URL.createObjectURL(event.target.files[0]);
    this.setState({
        photoURL: value,
        image: event.target.files[0]
    })
  }
  uploadImage = async() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    let file = this.state.image;
    let imageName = 'image-' + this.state.random;
    const storageRef = ref(storage, imageName);
    await uploadBytes(storageRef, file)
    .then(() => {
        getDownloadURL(storageRef).then((url)=> {
            this.setState({
                imgUrl: url
            })
            this.updateImageData(url)
        })
    })
  }
  updateImageData = async(url) => {
    const app = initializeApp(firebaseConfig);
    let docID = ""
    let email = this.state.userData.email
    const db = getFirestore(app);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docID = doc.id
    });
    console.log(url)
    const userRef = doc(db, "users", docID);
    await setDoc(userRef, { photoURL: url }, { merge: true });
  }
  handleNFTDomains = async () => {
    const API_URL = 'https://resolve.unstoppabledomains.com';
    const API_TOKEN = '907825be-28af-4371-9db4-1cc59930849c';
    let owner = this.state.userData.walletAddress;
    if(owner && owner != ""){
        try {
            const response = await axios.get(`${API_URL}/domains`, {
              params: {
                sortBy: 'name',
                sortDirection: 'ASC',
                perPage: 50,
                owners: owner,
              },
              headers: {
                Authorization: `Bearer ${API_TOKEN}`,
              },
            });
            console.log(response.data.data[0].attributes.meta.domain);
            this.setState({
                nftData: response.data.data,
                unstoppableAddress: response.data.data[0].attributes.meta.domain,
                noNFT: false
            })
            return response.data;
          } catch (error) {
            console.error(error);
          }
    }
    else{
        this.setState({
            noNFT: true
        })
    }
  }
  render() {
    return (
        <>
            <div className="settings_body">
                <p className="settings_header_title">Settings</p>
                <div className="dashboard_domains_tab">
                    {
                        this.state.preferredView === "Profile" ?
                            <button className="settings_domains_tab_active_first">
                                <span className="searchBtn_txt">Profile</span>
                            </button>
                        :
                            <button className="settings_domains_tab_inactive_first" onClick={() => this.handleFilter("All")}>
                                <span className="searchBtn_txt">Profile</span>
                            </button>
                    }
                    {
                        this.state.preferredView === "Notification" ?
                            <button className="settings_domains_tab_active">
                                <span className="searchBtn_txt">Notification</span>
                            </button>
                            :
                            <button className="settings_domains_tab_inactive" onClick={() => this.handleFilter(".nft")}>
                                <span className="searchBtn_txt">Notification</span>
                            </button>
                    }
                    {
                        this.state.preferredView === "Billing" ?
                            <button className="settings_domains_tab_active">
                                <span className="searchBtn_txt">Billing</span>
                            </button>
                            :
                            <button className="settings_domains_tab_inactive" onClick={() => this.handleFilter(".crypto")}>
                                <span className="searchBtn_txt">Billing</span>
                            </button>
                    }
                    {
                        this.state.preferredView === "Security" ?
                            <button className="settings_domains_tab_active">
                                <span className="searchBtn_txt">Security</span>
                            </button>
                            :
                            <button className="settings_domains_tab_inactive" onClick={() => this.handleFilter(".blockchain")}>
                                <span className="searchBtn_txt">Security</span>
                            </button>
                    }
                </div>
                <Row>
                    <Col md={7}>
                        <div className="profile_left_firstDiv">
                            <Row>
                                <Col md={2} sm={3} xs={3}>
                                    {
                                        this.state.photoURL != "" ?
                                            <>
                                                <img src={this.state.photoURL} className="settings_profileImage_empty"/>
                                                {
                                                    this.state.editInfoView ?
                                                    <label id="pic1">
                                                        <input
                                                            type="file"
                                                            name="fileToUpload"
                                                            id="fileToUpload"
                                                            size="1"
                                                            accept="image/*"
                                                            onChange={(event) => {this.handleImage(event)}}
                                                        />
                                                        <center>
                                                            <AiOutlinePlus
                                                                size={18}
                                                                className="AiOutlinePlus-icon2"
                                                            />
                                                        </center>
                                                    </label>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        :
                                            <>
                                                <img src={user_img} className="settings_profileImage_empty"/>
                                                {
                                                    this.state.editInfoView ?
                                                    <label id="pic1">
                                                        <input
                                                            type="file"
                                                            name="fileToUpload"
                                                            id="fileToUpload"
                                                            size="1"
                                                            accept="image/*"
                                                            onChange={(event) => {this.handleImage(event)}}
                                                        />
                                                        <center>
                                                            <AiOutlinePlus
                                                                size={18}
                                                                className="AiOutlinePlus-icon2"
                                                            />
                                                        </center>
                                                    </label>
                                                    :
                                                    <></>
                                                }
                                            </>
                                    } 
                                </Col>
                                <Col md={9} sm={7} xs={7}>
                                    {
                                        this.state.editInfoView ?
                                        <>
                                            <input
                                                className="setFullName_settings"
                                                type="text"
                                                placeholder="Enter Full Name"
                                                onChange={(e) => {this.handleName(e)}}
                                                value={this.state.fullName}
                                            />
                                        </>
                                        :
                                        <>
                                        {
                                            this.state.fullName === undefined ?
                                                <p className="settings_fullName">Name not set</p>
                                            :
                                                <p className="settings_fullName">{this.state.fullName}</p>
                                        }
                                        </>
                                    }
                                    {
                                        this.state.noNFT ?
                                            <p className="settings_walletAddress">Get NFT Domains</p>
                                        :
                                            <p className="settings_walletAddress">{this.state.unstoppableAddress}<FaRegCopy size={14} className="FaRegCopy-icon" onClick={() => {navigator.clipboard.writeText(this.state.unstoppableAddress)}}/></p>
                                    }
                                    
                                </Col>
                                <Col md={1} sm={2} xs={2}>
                                    {
                                        this.state.editInfoView ?
                                            <TfiSave size={20} className="FaRegCopy-icon"  onClick={() => this.saveData()}></TfiSave>
                                        :
                                            <FiEdit size={20} className="FaRegCopy-icon" onClick={() => this.setState({editInfoView: true})}></FiEdit>
                                    }
                                
                                </Col>
                            </Row>
                            <p className="profile_bio">Two parts product, one part data, finance to taste. I write about systems, organizational design, and crypto.</p>
                            {
                                this.state.userData.walletAddress ?
                                <>
                                    
                                    <div className="wallet_greyTab_primary">
                                        <Row>
                                            <Col md={2} sm={2} xs={2}>
                                                <img src={ethereum} className="wallets_chain_icons" />
                                            </Col>
                                            <Col md={10} sm={10} xs={10}>
                                                <p className="wallet_greyTabTxt">{this.state.slicedWallet}<FaRegCopy size={14} className="FaRegCopy-icon" onClick={() => {navigator.clipboard.writeText(this.state.userData.walletAddress)}}/></p>
                                            </Col>
                                        </Row>
                                    </div>
                                    {
                                        this.state.userData.otherWallets != [] ?
                                        <>
                                            {this.otherWalletRender()}
                                        </>
                                        :
                                        <></>
                                    }
                                    <br clear="all" />
                                    <img src={primary} className="primary"/>
                                    <br clear="all"></br>
                                </>
                                :
                                <p className="profile_humanityTxt_grey"><img src={setting_wallet} className="profile_humanity"/>No wallet connected</p>
                            }
                            <hr className="profile_hr"/>
                            <p className="profile_humanityTxt"><img src={profile_humanity} className="profile_humanity"/>Humanity Verified</p>
                            <p className="profile_humanityTxt_black"><img src={profile_location} className="profile_humanity"/>Nagaland, India</p>
                            <p className="profile_humanityTxt_black"><img src={profile_mail} className="profile_humanity"/>{this.state.email}</p>
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">NFT Domains</p>
                            <p className="profile_bio">Here's the list of domains associated with the wallets connected</p>
                            {
                                this.state.userData.walletAddress ?
                                    <Carousel
                                        autoPlay={true}
                                        autoPlaySpeed={1600}
                                        transitionDuration={1200}
                                        responsive={responsive}
                                    >
                                        {
                                            this.state.nftData.map((item, key) =>(
                                                <div className="nftDomain">
                                                    <img src={ud_nft} className="ud_nft"/>
                                                    <div className="ud_nft_inner">
                                                        <p className="ud_nft_txt">{item.attributes.meta.domain}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                :
                                <Link className="links_text" to="/Domains">
                                    <div className="profile_skyblueBtn">
                                        <p className="profile_skyblueBtn_txt">Get Yours NFT Domain</p>
                                    </div>
                                </Link>
                            }
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Two Factor Authentication</p>
                            <p className="profile_bio">Enabling two factor authentication helps keep your domains safe.</p>
                            <div className="profile_skyblueBtn">
                                <p className="profile_skyblueBtn_txt">Setup 2FA</p>
                            </div>
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Claim Your Free Domain</p>
                            <p className="profile_bio">Get two free .blockchain domain through Verity Verifications. Use your domain to receive crypto and much more.</p>
                            <Link className="links_text" to="/Domains">
                                <div className="profile_skyblueBtn">
                                    <p className="profile_skyblueBtn_txt">Claim Domain</p>
                                </div>
                            </Link>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Account</p>
                            {
                                this.state.isGoogleConnected ?
                                <p className="profile_bio">Reset Password unavailable since you signed up with Google.</p>
                                :
                                <></>
                            }
                            {
                                this.state.isLinkedInConnected ?
                                <p className="profile_bio">Reset Password unavailable since you signed up with LinkedIn.</p>
                                :
                                <></>
                            }
                            {
                                this.state.isSocialAccountConnected ?
                                <></>
                                :
                                <p className="profile_bio">Here you can edit public information about yourself. The changes will be displayed for others within 5 minutes.</p>
                            }
                            {
                                this.state.passwordView ?
                                    <>
                                        <p className="oldPasswordInput_labels">Old Password</p>
                                        <input
                                            className="oldPasswordInput"
                                            type="password"
                                            placeholder="Enter last used password"
                                            onChange={(e) => {this.handleOldPassword(e)}}
                                            value={this.state.oldPassword}
                                        />
                                        <p className="oldPasswordInput_labels">New Password</p>
                                        <input
                                            className="oldPasswordInput"
                                            type="password"
                                            placeholder="Enter new password"
                                            onChange={(e) => {this.handleNewPassword(e)}}
                                            value={this.state.newPassword}
                                        />
                                        <p className="oldPasswordInput_labels">Confirm Password</p>
                                        <input
                                            className="oldPasswordInput"
                                            type="password"
                                            placeholder="Confirm new password"
                                            onChange={(e) => {this.handleConfirmPassword(e)}}
                                            value={this.state.confirmPassword}
                                        />
                                        <div className="settings_save_btn" onClick={() => this.resetPassword()}>
                                            <p className="profile_save_btn_txt">Save changes</p>
                                        </div>
                                    </>
                                :
                                    <>
                                    {
                                        this.state.isSocialAccountConnected ?
                                        <div className="profile_skyblueBtn_disabled">
                                            <p className="profile_skyblueBtn_txt_disabled">Reset Password</p>
                                        </div>
                                        :
                                        <div className="profile_skyblueBtn" onClick={() => this.setState({passwordView: true})}>
                                            <p className="profile_skyblueBtn_txt">Reset Password</p>
                                        </div>
                                    }
                                    </>
                                    
                            }
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Wallet</p>
                            <p className="profile_bio">Connect or create new wallet to be linked with the crypto domains.</p>
                            <WagmiConfig client={wagmiClient}>
                                <RainbowKitProvider chains={chains}>
                                    <ConnectButton.Custom>
                                        {({
                                            account,
                                            chain,
                                            openAccountModal,
                                            openChainModal,
                                            openConnectModal,
                                            authenticationStatus,
                                            mounted,
                                        }) => {
                                            const ready = mounted && authenticationStatus !== 'loading';
                                            const connected =
                                            ready &&
                                            account &&
                                            chain &&
                                            (!authenticationStatus ||
                                            authenticationStatus === 'authenticated');
                                            return (
                                            <div
                                                {...(!ready && {
                                                'aria-hidden': true,
                                                'style': {
                                                    opacity: 0,
                                                    pointerEvents: 'none',
                                                    userSelect: 'none',
                                                },
                                                })}
                                            >
                                                {(() => {
                                                if (!connected) {
                                                    return (
                                                        <div className="profile_skyblueBtn" onClick={openConnectModal}>
                                                            <p className="profile_skyblueBtn_txt">Connect Wallet</p>
                                                        </div>
                                                    );
                                                }

                                                if (chain.unsupported) {
                                                    return (
                                                    <button onClick={openChainModal} type="button">
                                                        Wrong network
                                                    </button>
                                                    );
                                                }
                                                return (
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="profile_skyblueBtn" onClick={openChainModal}>
                                                                <p className="profile_skyblueBtn_txt">Switch Chain</p>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="profile_greenBtn" onClick={() => this.handleWalletLogin(account)}>
                                                                <p className="profile_greenBtn_txt">Save Wallet Details</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    
                                                );
                                                })()}
                                            </div>
                                            );
                                        }}
                                    </ConnectButton.Custom>
                                </RainbowKitProvider>
                            </WagmiConfig>
                        </div>
                        <div className="profile_left_firstDiv">
                            <p className="settings_TabsTittle">Humanity Check</p>
                            <p className="profile_bio">You haven’t upload KYC yet. Please upload the required documents for further process.</p>
                            <div className="profile_skyblueBtn">
                                <p className="profile_skyblueBtn_txt">Upload KYC</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            <Footer/>
        </>
    );
  }
}

export default withRouter(Settings);
