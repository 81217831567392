import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FiSearch } from "react-icons/fi";
import { MdOutlineSecurity } from "react-icons/md";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'


import user_img from "../assets/user_img.png";
import "./FreeDomains.css";

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load({
  apiKey: "jdR4AYlQ1Ir7NtzikMO3",
  region: "ap"
})

const firebaseConfig = {
    apiKey: "AIzaSyBUbLDQeX3wFCC1QMsBxny03mXjs2k2MGs",
    authDomain: "verityverification0.firebaseapp.com",
    databaseURL: "https://verityverification0-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "verityverification0",
    storageBucket: "verityverification0.appspot.com",
    messagingSenderId: "709250129230",
    appId: "1:709250129230:web:321b332165e243328a521f",
    measurementId: "G-QX1DD7CMM7"
};

class FreeDomains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: "",
            domainData: [],
            halfValue : 0,
            preferenceDomain : "All"
        }
        this.handleDomainName = this.handleDomainName.bind(this);
    }
  async componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.getDomainResults();
  }
  handleDomainName = (event) => {
    let errors = this.state.errors;
    let domain = event.target.value.length
    if (domain>0) {
      errors["domain"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["domain"] = "Cannot be empty";
      this.setState({ errors: errors});
    }
    this.setState({
      domain: event.target.value,
    });
  }
  getDomainResults = () => {
    let domain = this.state.domain;
    fetch(`https://unstoppabledomains.com/api/v2/resellers/identityunchained/domains/suggestions/free?search=`+domain, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          domainData: responseJson,
          halfValue: Object.keys(responseJson).length/2
        })
        console.log("OUTPUT ==> ", Object.keys(responseJson).length/2);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleFilter = (domain) => {
    this.setState({
      preferenceDomain: domain
    })
  }
  renderDomainData = () => {
    let domainData = this.state.domainData;
    let halfValue = this.state.halfValue;
    let slicedDomainsLeft = domainData.slice(0, halfValue)
    let slicedDomainsRight = domainData.slice(halfValue+1, )
    return(
      <div>
        <Row>
          <Col md={6}>
            {
              slicedDomainsLeft.map((item, key) =>(
                <>
                  {
                    key%2 === 0 ?
                      <div className="domains_boxes_odd">
                        <Row>
                          <Col md={8}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3}>
                            <button className="buyBtn" onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    :
                    <div className="domains_boxes_even">
                        <Row>
                          <Col md={8}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3}>
                            <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                    </div>
                  }
                </>
              ))
            }
          </Col>
          <Col md={6}>
            {
              slicedDomainsRight.map((item, key) =>(
                <>
                  {
                    key%2 === 0 ?
                      <div className="domains_boxes_odd_forRight">
                        <Row>
                          <Col md={8}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3}>
                            <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    :
                    <div className="domains_boxes_even_forRight">
                        <Row>
                          <Col md={8}>
                            <span className="domains_boxes_odd_txt">{item.name}</span>
                          </Col>
                          <Col md={1}>
                            <span className="domains_boxes_odd_price">${item.price}</span>
                          </Col>
                          <Col md={3}>
                            <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                              <span>Buy</span>
                            </button>
                          </Col>
                        </Row>
                    </div>
                  }
                </>
              ))
            }
          </Col>
        </Row>
      </div>
    )
  }
  renderFilteredDomainData = () => {
    let preferenceDomain = this.state.preferenceDomain;
    let domainData = this.state.domainData;
    let halfValue = this.state.halfValue;
    return(
      <div>
        <Row>
          <Col md={6}>
            {
              domainData.map((item, key) =>(
                <>
                  {
                    item.name.includes(preferenceDomain) ? 
                    <>
                      {
                        key%2 === 0 ?
                          <div className="domains_boxes_odd">
                            <Row>
                              <Col md={8}>
                                <span className="domains_boxes_odd_txt">{item.name}</span>
                              </Col>
                              <Col md={1}>
                                <span className="domains_boxes_odd_price">${item.price}</span>
                              </Col>
                              <Col md={3}>
                                <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                                  <span>Buy</span>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        :
                        <div className="domains_boxes_even">
                            <Row>
                              <Col md={8}>
                                <span className="domains_boxes_odd_txt">{item.name}</span>
                              </Col>
                              <Col md={1}>
                                <span className="domains_boxes_odd_price">${item.price}</span>
                              </Col>
                              <Col md={3}>
                                <button className="buyBtn"  onClick={() => this.addToCart(item)}>
                                  <span>Buy</span>
                                </button>
                              </Col>
                            </Row>
                        </div>
                      }
                    </>
                    :
                    <></>
                  }
                </>
              ))
            }
          </Col>
        </Row>
      </div>
    )
  }
  render() {
    return (
        <div>
            <div className="dashboard_searchMain">
                <div className="dashboard_body_LR_background_gray"></div>
                <Row style={{marginTop: "15%"}}>
                  <Col md={10} sx={12} xs={12}>
                      <input
                          className="dashboard_DomainInput"
                          type="text"
                          value={this.state.domain}
                          placeholder="Search for your name.crypto"
                          onChange={(e) => {this.handleDomainName(e)}}
                      />
                      
                  </Col>
                  <Col md={2}>
                    <button className="searchBtn" onClick={this.getDomainResults}>
                      <FiSearch size={23} className="FiSearch-icon"/><span className="searchBtn_txt">Search</span>
                    </button>
                  </Col>                   
                </Row>
                <p className="searchResults_txt">Result for : "{this.state.domain}"</p>
                <div className="dashboard_domains_tab">
                  {
                    this.state.preferenceDomain === "All" ?
                      <button className="dashboard_domains_tab_active_first">
                        <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">All domains</span>
                      </button>
                    :
                      <button className="dashboard_domains_tab_inactive_first" onClick={() => this.handleFilter("All")}>
                        <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">All domains</span>
                      </button>
                  }
                  {
                    this.state.preferenceDomain === ".nft" ?
                    <button className="dashboard_domains_tab_active">
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.nft</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".nft")}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.nft</span>
                    </button>
                  }
                  {
                    this.state.preferenceDomain === ".crypto" ?
                    <button className="dashboard_domains_tab_active">
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.crypto</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".crypto")}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.crypto</span>
                    </button>
                  }
                  {
                    this.state.preferenceDomain === ".blockchain" ?
                    <button className="dashboard_domains_tab_active">
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.blockchain</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".blockchain")}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.blockchain</span>
                    </button>
                  }
                  {
                    this.state.preferenceDomain === ".wallet" ?
                    <button className="dashboard_domains_tab_active">
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.wallet</span>
                    </button>
                    :
                    <button className="dashboard_domains_tab_inactive" onClick={() => this.handleFilter(".wallet")}>
                      <MdOutlineSecurity size={23} className="FiSearch-icon"/><span className="searchBtn_txt">.wallet</span>
                    </button>
                  }
                </div>
                {
                  this.state.preferenceDomain === "All" ?
                    <>
                      {this.renderDomainData()}
                    </>
                  :
                    <>
                      {this.renderFilteredDomainData()}
                    </>
                }
                <br/><br/>
            </div>
        </div>
    );
  }
}

export default withRouter(FreeDomains);
